import React,{useContext, useEffect, useState} from 'react';
import { Form, Col, Row, Card, Button } from '@themesberg/react-bootstrap';
import { Input, Table, Label } from 'reactstrap';
import { FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faAdd , faCheckSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import {ProductContext} from "../../ProductProvider";
import { Alert } from "reactstrap";
import {familyRelationship, phoneCode} from '../../ProData';
import { nanoid } from 'nanoid'


export default function LanguageDetails(props) {
  const { setSaveFamilyData, alertDetails, setAlertDetails, alertClose, memberInfo, setMemberInfo, calculateProgressiveValues } = useContext(ProductContext);
  const [localFD, setLocalFD]=useState({id:'1',language:"",read:false,speak:false, write:false});
  const {setTabValue, viewOnly} = props;

  useEffect(()=>{
    alertClose();
    generateNewID();
  },[])

  const generateNewID=()=>{
    const idgen=nanoid(10);
    setLocalFD({...localFD,
      id:idgen,language:"",read:false,speak:false, write:false});
  }

  const handleLangAdd =async(event)=>{
    event.preventDefault();
    if(localFD.language===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Language cannot be empty"
    }); 
    }else{
      await setMemberInfo({...memberInfo,
      languageDetails:[...memberInfo.languageDetails, localFD]});
      generateNewID();
      calculateProgressiveValues(memberInfo);
    }

  }

  const handleLangSave=(event)=>{
    event.preventDefault();
    setSaveFamilyData(true);
    setTabValue("Studies");

  }

  const handleRemoveLang=async(event, member)=>{
    event.preventDefault();
    let tempCart=[...memberInfo.languageDetails];
    tempCart = tempCart.filter(item => item.id !==member.id);
    await setMemberInfo({
       ...memberInfo,
       languageDetails:[...tempCart]
     });
    calculateProgressiveValues(memberInfo);
    generateNewID();
  }


  return (
    <div>
         <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Language information</h5>
        <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
<h6>Add Language Details</h6>
        <Row >
            <Col>
                <form > 
                {!viewOnly? <div className="navbar-bg" style={{marginBottom:'20px'}}>
                <FormGroup style={{overflow:"visible"}}>
               <div>
                <Row>
                  <Col xs={9} lg={4}>
                  <Input
                        type="text"
                        name="languageName"
                        placeholder="Language"
                        value={localFD.language}
                        onChange={(event)=>{setLocalFD({...localFD,
                        language:event.target.value})}}
                      />
                  </Col>
                    <Col xs={9} lg={2}>
                    <Form.Check inline 
            type="checkbox" name={"Read"} 
            label={"Read"} 
            checked={localFD.read} 
            onChange={(event)=>setLocalFD({...localFD,read:!localFD.read})} />
                    </Col>
                    <Col xs={9} lg={2}>
                    <Form.Check inline 
            type="checkbox" name={"Speak"} 
            label={"Speak"} 
            checked={localFD.speak} 
            onChange={(event)=>setLocalFD({...localFD,speak:!localFD.speak})} />
                 </Col>
                  <Col xs={9} lg={2}>
                  <Form.Check inline 
            type="checkbox" name={"Write"} 
            label={"Write"} 
            checked={localFD.write} 
            onChange={(event)=>setLocalFD({...localFD,write:!localFD.write})} />
                 </Col>
                 <Col xs={9} lg={2}>
                 <Button title="Add User" icon={faAdd} onClick={(event)=>{handleLangAdd(event)}} >ADD</Button>
                 </Col>

                 </Row>
                  </div>
            </FormGroup>
       </div> :null}  
       <Table hover responsive>
  <thead>
    <tr>
      <th>#</th>
      <th>Language</th>
      <th>Read</th>
      <th>Speak</th>
      <th>write</th>
    </tr>
  </thead>
  <tbody>
  {memberInfo.languageDetails && memberInfo.languageDetails.map((item, index)=>
    <tr key={index}>
      <th scope="row">{index+1}</th>
      <td>{item.language}</td>
      <td>{item.read?<FontAwesomeIcon icon={faCheckSquare} className="me-2"/>:null}</td>
      <td>{item.speak?<FontAwesomeIcon icon={faCheckSquare} className="me-2"/>:null}</td>
      <td>{item.write?<FontAwesomeIcon icon={faCheckSquare} className="me-2"/>:null}</td>
     {!viewOnly?<td><FontAwesomeIcon icon={faTrash} className="me-2" onClick={(event)=>{handleRemoveLang(event, item)}}/></td>:null}
    </tr>
  )}
  </tbody>
</Table>
       <FormGroup>
                 <div className="container text-center">
            <div className="d-flex justify-content-around btn ">
           <button type="submit" onClick={(event)=>{handleLangSave(event)}}
               className="text-center bg-primary button-approve is-info" style={{width:"80px", border: 'none', borderRadius:'8px', color:'white'}}
             >Next</button>
            </div>
            </div>
                </FormGroup>
                  </form>
            </Col>           
          </Row>
      </Card.Body>
    </Card>
    </div>
  )
}
