import React, { useEffect, useState, useContext , useRef} from 'react';
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import {Breadcrumb, BreadcrumbItem, Alert, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { FormGroup } from 'react-bootstrap';
import axios from "axios";
import { Auth } from "aws-amplify";
import {ProductContext} from "../../ProductProvider";
import Preloader from "../../components/Preloader";
import { Routes } from "../../routes";

const config = require('../../config.json');

export default function NewRequestForm() {
  const ref = useRef();
  const { loggedUser, alertClose, alertDetails, setAlertDetails, validateLogin } = useContext(ProductContext);
  const [request, setRequest]=useState({communityName:"SJMDU", reqid:"",reqCreateDate:"",reqCreateTime:"",reqSubject:"",reqComments:"",reqStatus:"New",reqRaisedBy:loggedUser.name, fileAttached:false, attachmentName:null})
  const [attachment, setAttachment]=useState('');
  const [fileExtn, setFileExtn]=useState('');
  //const [attachmentFileName, setAttachmentFileName]=useState(null);
  const [loaded, setLoaded] = useState(true);

useEffect(()=>{
  if(loggedUser.isAuthenticated){
    alertClose();
    assignInitialValues();
  }else{
  validateLogin();
  alertClose();
  }
  return()=>{
    alertClose();
  }
 // console.log(batchYear);
},[loggedUser])

const assignInitialValues=()=>{
    const current_datetime = new Date();
    const tusername=loggedUser.name.trim().replace(/\s/g, "");
    const tempUserId= tusername.substring(0,4);
    var tempCreateDate="";
    var tempCreateTime="";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value);
    const accCreateDate = tempCreateDate.concat(date_year,"-",month_value,"-", date_value);
    const accCreateTime = tempCreateTime.concat(hours_value,":", minutes_value);

    //setUserId(accountNo);
    //return accountNo;
    setRequest({...request,
      reqid:accountNo,
      reqCreateDate:accCreateDate,
      reqCreateTime:accCreateTime,
      reqRaisedBy:loggedUser.name
    })
    //console.log("acc : ", accountNo);
  
  
}

const handleSelectFile = async(event)=>{
  event.preventDefault();
  setLoaded(false);
  alertClose();
  //console.log("inside");
  if (event.target.files && event.target.files.length > 0) {
    if(event.target.files[0].size>1000000){
      const fileSize=event.target.files[0].size/1000000;
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"File Size limit is 1MB. Your current file size is - " + fileSize.toFixed(2) + "MB."
    }); 
     // console.log("Event : ", event.target.files[0].size);

    }else{
      //console.log("Event : ", event.target.files[0]);
      setAttachment(event.target.files[0]);
      const fileName=event.target.files[0].name;
      const tfileExtn=fileName.substring(fileName.indexOf('.'),fileName.length);
      setFileExtn(tfileExtn);
     // console.log("Extn : ", fileExtn);
    }
  }
  setLoaded(true);

}
const handleRequestSubmit=async(event)=>{
  event.preventDefault();
  try {
    if(request.reqSubject==="" || request.reqComments===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Enter subject and description"
      }); 
    }
    else{
       // const treqid= await generateReqId();
       setLoaded(false);

       if(request.reqid===""){
        assignInitialValues();
       }
       if(attachment){
       await postOnS3();
       }
       const newFileName=request.reqid+fileExtn;

        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        const { communityName, reqid,reqCreateDate, reqCreateTime, reqSubject, reqComments, reqStatus, reqRaisedBy, fileAttached}=request;
        const params = {
            "communityName": communityName,
            "reqNo": reqid,
            "reqCreateDate":  reqCreateDate,
            "reqCreateTime":reqCreateTime,
            "reqSubject":reqSubject,
            "reqComments": reqComments,
            "reqStatus": reqStatus,
            "reqRaisedBy":reqRaisedBy,
            "reqWorkedBy": "NA",    
            "reqCloseDate": "NA",
            "reqResolutionNotes":"NA",
            "fileAttached":fileAttached,
            "attachmentName":newFileName
        };
              await axios.post(`${config.api.invokeUrl}/user_request/${reqid}`, params,{
    headers: {
      Authorization: access_token,
      'x-api-key':config.api.key
    }})
    .then(async()=>{
      //console.log("response : ", response); 
        setAlertDetails({
          ...alertDetails,
          style:"success",
          open:true,
          message:request.reqid + " - Submitted Successfully"
      }); 
       // handleCancelClick(event);
       resetRequestValues();
        setLoaded(true);

    })
    .catch((error)=>{
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Error Occured : " + error.message
      }); 
                      setLoaded(true);

    })
  }

}catch (err) {
      setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message:"Error Occured - " + err.message
}); 
setLoaded(true);

}

}

const postOnS3 = async()=>{
  try{
      //  console.log("NewImage : ", newImage);
    const newFileName=request.reqid+fileExtn;
    //console.log("newfilename :", newFileName);
    
    var file = attachment;
    var blob = file.slice(0, file.size, file.type); 
    const newFile = new File([blob], newFileName, {type: file.type});
   // console.log("New file : ", newFile);
    const session = await Auth.currentSession();
    const access_token=session.idToken.jwtToken;   
    const bucketName=config.s3.attachmentBucketName;
    await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
   // await axios.get(`${config.api.invokeURL}/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
         headers:{
             Authorization: access_token,
             'x-api-key': config.api.key
         }
     }).then(async(response)=>{
        // console.log("Response : ", response);
        const res=await fetch(response.data.uploadURL, { method: 'PUT',
         headers:{
             "Content-Type":"image/jpeg"
           },body: newFile});
           console.log("Res : ", res);
           if(res.status===200){
              setRequest({...request,
              fileAttached:true});
              ref.current.value=null;
            //  fetchGalleryImages();
              // setProfileImage(newFileName);
               //var timestamp = new Date().getTime();     
              // var tprofile = document.getElementById("profile");
               //var imgsrc="";
               //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
               //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
              // tprofile.src=imgsrc;
              //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
               //console.log("tprofle : ", tprofile.src);
           }
      }).catch((error)=>{
          //console.log("error1:", error);
          setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
       // setDispMsg(error.message);
       // hideLoader();
     })
  
  }catch(error){
  //  console.log("error1:", error);
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message:"Error Occured - " + error.message
  }); 
  }

}

const resetRequestValues=()=>{
  setRequest({communityName:"SJMDU", reqid:"",reqCreateDate:"",reqCreateTime:"",reqSubject:"",reqComments:"",reqStatus:"New",reqRaisedBy:loggedUser.name, fileAttached:false, attachmentName:null});
  setAttachment('');
  setFileExtn('');
  console.log("resetted");

}
  return (
    <div>
               <Preloader show={loaded ? false : true} />
               <Breadcrumb>
    <BreadcrumbItem>
      <Link to={Routes.LandingPage.path}>
        Home
      </Link>
    </BreadcrumbItem>
    <BreadcrumbItem >
      Request
    </BreadcrumbItem>
    <BreadcrumbItem >
      New Request
    </BreadcrumbItem>
  </Breadcrumb>

<Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Request Form</h5>

        <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
   Request No: {request.reqid}
          <Row className='container p-4'>
            <Col>
                <form > 
                <div className="navbar-bg">
                <FormGroup style={{overflow:"visible"}}>
                <div>
                <Row>
                  <Input
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        onChange={(event)=>setRequest({...request,
                          reqSubject:event.target.value})}
                      />
                  </Row>
                  </div>
                    </FormGroup>
                    <FormGroup style={{overflow:"visible"}}>
                    <div>
                    <Row>
                  <Input type="textarea" name="msg"
                      placeholder="Enter detailed description"
                      onChange={(event)=>setRequest({...request,
                        reqComments:event.target.value})}
                      />
                  </Row>
                  </div>
                    </FormGroup>
       </div>
       <FormGroup check row>
                 <div className="new-request">
            <div >
              <Row className='d-flex justify-content-between '>
                <Col  xs={12} lg={6} style={{paddingTop:'20px',textAlign:'center'}}>
                <input className='btn-submit btn-choosefile'  type="file" id="uploadfile" ref={ref} onChange={(event)=>{handleSelectFile(event)}} />
                </Col>
                <Col xs={12} lg={6} style={{paddingTop:'20px',textAlign:'center'}}>
                <button className='btn-submit' type="submit" onClick={(event)=>{handleRequestSubmit(event)}}> Submit</button>
                </Col>
                </Row>
               
                
            </div>
            </div>
                </FormGroup> 
                  </form>
            </Col>           
          </Row>
      </Card.Body>
    </Card>
    </div>
  )
}
