import React, { useState, useContext } from "react";
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import { Tab, Tabs } from 'react-bootstrap';
import GenInfor from "../profile/GenInfor";
import SocSJInfo from "../profile/SocSJInfo";
import FamInfo from "../profile/FamInfo";
import AcademicInfo from "../profile/AcademicInfo";
import ProfileImage from "../profile/ProfileImage";
import ProgressBarStatus from '../profile/ProgressBarStatus';
import {ProductContext} from '../../ProductProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faEnvelope , faPhone} from "@fortawesome/free-solid-svg-icons";
import FormationProfile from "../profile/FormationProfile";
import CommunityProfile from "../profile/CommunityProfile";
import LanguageDetails from "../profile/LanguageDetails";

export default (props) => {
  const { progressValue, memberInfo, newImage, setNewImage } = useContext(ProductContext);
  const {formType, profileImage, viewOnly, setProfileImage} = props;
  const [tabValue, setTabValue]=useState("Home")
 // const [newImage, setNewImage]=useState('');

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center" >
      {/*  <div className="d-block mb-4 mb-md-0 align-items-center">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Catalogue</Breadcrumb.Item>
            <Breadcrumb.Item active>Add User</Breadcrumb.Item>
          </Breadcrumb>
          <h4 >ADD USER</h4>
          <p className="mb-0"></p>
        </div>
  */}
          
      </div>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
      <Row className="justify-content-md-center">
        <Col xs={4} sm={2} xl={2} className="mb-2">
        
        <ProfileImage profileImage={profileImage} viewOnly={viewOnly} setNewImage={setNewImage}/>
        </Col>
        <Col xs={8} sm={10} xl={8} className="mb-2">
        <div style={{textAlign:"right"}}> 
 <h3> {memberInfo.name} </h3>
 {memberInfo.phoneNo==="NA"||memberInfo.phoneNo===""?null:<h6> <FontAwesomeIcon icon={faPhone} className="me-2" />{memberInfo.areaCode} {memberInfo.phoneNo} </h6>}
 {memberInfo.emailID==="NA"||memberInfo.emailID===""?null:<h6>  <FontAwesomeIcon icon={faEnvelope} className="me-2"/>{memberInfo.emailID} </h6>}
      </div>
        </Col>
      </Row>
      </Card.Body> 
      </Card>
      
    
      <div className='container'><ProgressBarStatus  progressValue={progressValue} dispValue="Profile"/>
           </div>

      <div className="table-settings mb-4">
      <Tabs fill defaultActiveKey="Home" id="uncontrolled-tab-example"       
      activeKey={tabValue}
      onSelect={(k) => setTabValue(k)}
      className="mb-3">
    
    <Tab eventKey="Home" title="General">
   <GenInfor setTabValue={setTabValue} viewOnly={viewOnly}/>
  </Tab>
  <Tab eventKey="Family" title="Family">
   <FamInfo setTabValue={setTabValue} viewOnly={viewOnly}/>
  </Tab>
  <Tab eventKey="Society" title="Society">
    <SocSJInfo setTabValue={setTabValue} viewOnly={viewOnly}/>
  </Tab>
  <Tab eventKey="Language" title="Language">
    <LanguageDetails setTabValue={setTabValue} viewOnly={viewOnly}/>
  </Tab>

  <Tab eventKey="Studies" title="Studies" >
    <AcademicInfo setTabValue={setTabValue} formType={formType} viewOnly={viewOnly} newImage={newImage}/>
  </Tab>
  <Tab eventKey="Formation" title="Formation" >
    <FormationProfile setTabValue={setTabValue} formType={formType} viewOnly={viewOnly} newImage={newImage}/>
  </Tab>
  <Tab eventKey="Community" title="Community" >
    <CommunityProfile setTabValue={setTabValue} formType={formType} viewOnly={viewOnly} newImage={newImage}/>
    </Tab>

</Tabs>
      </div>

     
    </>
  );
};
