import React, {useState} from 'react';
import axios from "axios";
import { Auth } from "aws-amplify";
import { Routes } from "./routes";
import { useHistory } from "react-router-dom";

//import { withRouter, useHistory} from "react-router-dom";

const ProductContext = React.createContext();;
const config = require('./config.json');

const ProductProvider = (props) => {
const [loggedUser, setLoggedUser] = useState({
    name: 'Guest', nickname:'', email: '', website:'', profile:'Guest', DOB:'', isAuthenticated:false, role:"", isAdmin:false, provinceName:''});
const [myProfileImage, setMyProfileImage] = useState('defaultAvatar.jpg');
const [newMemberimage, setNewMemberImage] = useState('defaultAvatar.jpg');
const [saveFamilyData, setSaveFamilyData]=useState(false);
const [memberInfo, setMemberInfo] = useState({
    name:"", otherName:"", userId:"",missionName:"Madurai New", dobOfficial:"",dobActual:"",placeBirth:"",diocese:"",nationality:"", bloodGroup:"",emailID:"", areaCode:"+91", phoneNo:"",
    entranceDate:"", entrancePlace:"",firstVowDate:"",firstVowPlace:"",ordinationDate:"", ordinationPlace:"",ordainingBishop:"", tertainshipPlace:"",
    tertainshipEndDate:"", tertainInstructor:"",finalVowsDate:"", finalVowsPlace:"", grade:"Scholastic Novice", currentStatus:"Member", religiousStatus:"Brother", 
    provinceEntered:"",dateLeft:"", dateTransferred:"", transferredProvicialiateName:"",deathDate:"", deathPlace:"",burialPlace:"", familyDetails:[], studyDetails:[], languageDetails:[],
    formationDetails:[],communityDetails:[], progressValue:0, profileImage:'defaultAvatar.jpg'});
//const [memberLang, setMemberLang]=useState([]);
const [progressValue, setProgressValue]=useState(0);
const [notifications, setNotifications] = useState([]);
const [areNotificationsRead, setAreNotificationsRead]=useState(true);
const [newImage, setNewImage]=useState('');
const [orginalEmail, setOrginalEmail]=useState('');
const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});
const history= useHistory();

const assignValues=(item)=>{
   // console.log("Item : ", item);
    const {communityName, userId, username, missionName, currentStatus, dobOfficial, placeOfBirth, areaCode, phoneNo, emailId, dateOrdain, lastVow, Grade, religousStatus, nationality, familyDetails, studyDetails, otherName, dobActual, diocese, bloodGroup, 
        dateJoined, entrancePlace, firstVowDate, firstVowPlace, ordinationPlace, ordainingBishop, tertainshipPlace, tertainshipEndDate, tertainInstructor, dateLeft, finalVowsPlace, provinceEntered, dateTransferred, dateOfDeath, placeOfDeath, 
        placeOfBurial, provTransferred, progressValue, profileImage, formationDetails, communityDetails, languageDetails  }=item;
    var tempAssign={};
    const tMember={
        name:username, otherName:otherName,userId:userId, missionName:missionName, dobOfficial:dobOfficial,dobActual:dobActual,placeBirth:placeOfBirth,diocese:diocese,nationality:nationality, bloodGroup:bloodGroup,emailID:emailId, areaCode:areaCode, phoneNo:phoneNo,
        entranceDate:dateJoined, entrancePlace:entrancePlace,firstVowDate:firstVowDate,firstVowPlace:firstVowPlace,ordinationDate:dateOrdain, ordinationPlace:ordinationPlace,ordainingBishop:ordainingBishop, tertainshipPlace:tertainshipPlace,
        tertainshipEndDate:tertainshipEndDate, tertainInstructor:tertainInstructor,finalVowsDate:lastVow, finalVowsPlace:finalVowsPlace, grade:Grade, currentStatus:currentStatus, religiousStatus:religousStatus, provinceEntered:provinceEntered,
        dateLeft:dateLeft, dateTransferred:dateTransferred, transferredProvicialiateName:provTransferred,deathDate:dateOfDeath, deathPlace:placeOfDeath,burialPlace:placeOfBurial, studyDetails:studyDetails, familyDetails:familyDetails,
        formationDetails:formationDetails, communityDetails:communityDetails, profileImage:profileImage,progressValue:progressValue, languageDetails:languageDetails};
      //  console.log("Temp Value : ", tMember);
        setProgressValue(progressValue);
        for (const key in tMember){
         //   console.log("key : ", key);
         //   console.log("item key ", tMember[key]);
            //const tkey=tMember[key];
            if((key==="studyDetails" || key==="familyDetails" || key==="formationDetails" || key==="CommunityDetails"||key==="languageDetails")&& (tMember[key]===undefined||tMember[key]===null||tMember[key]==="")){
                tempAssign={...tempAssign, [key]:[]};

            }else if(tMember[key]){
             //   console.log("Empty key : ", tMember[key]);
                tempAssign={...tempAssign, [key]:tMember[key]};
            }else{
                tempAssign={...tempAssign, [key]:""};
            }
        }
        //console.log("temp assign values : ", tempAssign);
        setOrginalEmail(tempAssign.emailID);
        setMemberInfo({...memberInfo,
            name:tempAssign.name, otherName:tempAssign.otherName,userId:tempAssign.userId, missionName:tempAssign.missionName, dobOfficial:tempAssign.dobOfficial,dobActual:tempAssign.dobActual,placeBirth:tempAssign.placeBirth,diocese:tempAssign.diocese,nationality:tempAssign.nationality, bloodGroup:tempAssign.bloodGroup,emailID:tempAssign.emailID, areaCode:tempAssign.areaCode , phoneNo:tempAssign.phoneNo,
            entranceDate:tempAssign.entranceDate, entrancePlace:tempAssign.entrancePlace,firstVowDate:tempAssign.firstVowDate,firstVowPlace:tempAssign.firstVowPlace,ordinationDate:tempAssign.ordinationDate, ordinationPlace:tempAssign.ordinationPlace,ordainingBishop:tempAssign.ordainingBishop, tertainshipPlace:tempAssign.tertainshipPlace,
            tertainshipEndDate:tempAssign.tertainshipEndDate, tertainInstructor:tempAssign.tertainInstructor,finalVowsDate:tempAssign.finalVowsDate, finalVowsPlace:tempAssign.finalVowsPlace, grade:tempAssign.grade, currentStatus:tempAssign.currentStatus, religiousStatus:tempAssign.religiousStatus, provinceEntered:tempAssign.provinceEntered,
            dateLeft:tempAssign.dateLeft, dateTransferred:tempAssign.dateTransferred, transferredProvicialiateName:tempAssign.transferredProvicialiateName,deathDate:tempAssign.deathDate, deathPlace:tempAssign.deathPlace,burialPlace:tempAssign.burialPlace, studyDetails:tempAssign.studyDetails, familyDetails:tempAssign.familyDetails,
            formationDetails:tempAssign.formationDetails, communityDetails:tempAssign.communityDetails, profileImage:tempAssign.profileImage,progressValue:tempAssign.progressValue, languageDetails:tempAssign.languageDetails});
}

const alertClose=()=>{
    setAlertDetails({
        ...alertDetails,
        style:"",
        open:false,
        message:""
    });
}

const calculateProgressiveValues=(tprofiledata)=>{
    var countEmpty=0;
    //var countNotEmpty=0;
    for (var key in tprofiledata) {
    //  if(key==="tertainInstructor"||key!="provinceEntered"||key!="provTransferred"||key!="dateLeft"||key!="otherName"||key!="dateOfDeath"||key!="tertainshipPlace"||key!="placeOfBurial"||key!="dateTransferred"||key!="tertainshipEndDate"||key!="placeOfDeath"){
    if(key==="profileImage"||key==="religiousStatus"||key==="Grade"||key==="currentStatus"||key==="name"||key==="username"||key==="dobOfficial"||key==="placeBirth"||key==="phoneNo"||key==="emailID"||key==="nationality"||key==="diocese"||key==="bloodGroup"||key==="entranceDate"||key==="dateJoined"||key==="entrancePlace"||key==="firstVowDate"||key==="firstVowPlace") {   
        if (tprofiledata[key] ===""|| tprofiledata[key]==="NA"){
            countEmpty++;
          }
  }else if(key==="familyDetails"||key==="studyDetails"||key==="FormationDetails"||key==="languageDetails"){
    if(tprofiledata['familyDetails'].length===0||tprofiledata['formationDetails'].length===0||tprofiledata['studyDetails'].length===0||tprofiledata['languageDetails'].length===0){
        countEmpty++;
    }
  }
}
  const ttemp=21-countEmpty;
  const tprogressbarValue=((ttemp/21)*100).toFixed();
  setProgressValue(tprogressbarValue);
}

const handleImageError=(e)=>{
    // console.log("Event : ", e);
    e.target.src = "https://s3.ap-south-1.amazonaws.com/dev.idntica.com/img/gallery/defaultAvatar.jpg"


   }

const resetValues=()=>{
    setMemberInfo({...memberInfo,
        name:"", otherName:"", userId:"", dobOfficial:"",dobActual:"",placeBirth:"",diocese:"",nationality:"", bloodGroup:"",emailID:"", phoneNo:"",
        entranceDate:"", entrancePlace:"",firstVowDate:"",firstVowPlace:"",ordinationDate:"", ordinationPlace:"",ordainingBishop:"", tertainshipPlace:"",
        tertainshipEndDate:"", tertainInstructor:"",finalVowsDate:"", finalVowsPlace:"", grade:"Scholastic Novice", currentStatus:"Member", religiousStatus:"Brother", 
        provinceEntered:"",dateLeft:"", dateTransferred:"", transferredProvicialiateName:"",deathDate:"", deathPlace:"",burialPlace:"", studyDetails:[], familyDetails:[],
        communityDetails:[], formationDetails:[], languageDetails:[], progressValue:0, profileImage:'defaultAvatar.jpg'});
    setProgressValue(0);
}

const fetchOpenItems=async()=>{
    try {
      //console.log("testing");
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      const familyname="SJMDU";
      const searchOption="ReqStatus";
      const searchValue="New";
      const res = await axios.get(`${config.api.invokeUrl}/user_request?searchValue=${searchValue}&searchOption=${searchOption}&familyName=${familyname}`,{
          headers:{
              Authorization: access_token,
              'x-api-key': config.api.key
          }
      });
      if(res.data.length>0){
      setNotifications(res.data);  
      setAreNotificationsRead(false);
      }
      //console.log(res.data);
} catch (error) {
}

  }

  const generateDate=()=>{
    const current_datetime = new Date();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const accountNo = tempUserId.concat(date_year,"-",month_value,"-", date_value);
    //setUserId(accountNo);
    //setMemberInfo({...memberInfo, userId:accountNo});
    return accountNo;
    //console.log("acc : ", accountNo);
  
}

const validateLogin = async() =>{
    try{
       await Auth.currentAuthenticatedUser()
      .then(async(user)=>{
        var tadmin=false;
        if(user.attributes['profile']==="Admin"){
          await fetchOpenItems();
          //console.log("user : ", user);
         tadmin=true;
        }
        await setLoggedUser({...loggedUser,
          name:user.attributes['name'],
          email:user.attributes['email'],
          profile:user.attributes['profile'],
          DOB:user.attributes['birthdate'],
          provinceName:user.attributes['family_name'],
          nickname:user.attributes['nickname'],
          //website:user.attributes['website'],
          isAuthenticated:true,
          isAdmin:tadmin
        });
        const tImageName=user.attributes['nickname']+".jpeg";
        setMyProfileImage(`${config.s3.imageURL}${tImageName}`);
       // navigate("/");   
       history.push(Routes.DashboardOverview.path);
        
      })
      .catch((error) =>{
      //  console.log("error:", error);
        setLoggedUser({...loggedUser,
          name:'',
          email:'',
         // currentStatus:'',
          DOB:'',
          profile:'',
          //mobile:'',
          provinceName:'',
          isAuthenticated:false,
          isAdmin:false
        }); 
        history.push(Routes.Signin.path);
      })     
    }catch(error){
     // console.log(error);
    }
  }

    return (
        <ProductContext.Provider value={{
            loggedUser,
            setLoggedUser,
            setSaveFamilyData,
            assignValues,
            resetValues,
            progressValue,
            setProgressValue,
            calculateProgressiveValues,
            memberInfo,
            setMemberInfo, 
            myProfileImage,
            setMyProfileImage,
            newMemberimage, 
            setNewMemberImage,
            notifications,
            setNotifications,
            areNotificationsRead,
            setAreNotificationsRead,
            fetchOpenItems,
            newImage, 
            setNewImage,
            handleImageError,
            orginalEmail,
            setOrginalEmail,
            alertClose,
            alertDetails,
            setAlertDetails,
            generateDate,
            validateLogin
        }}>
        {props.children}
        </ProductContext.Provider>
    )
}

export {ProductProvider, ProductContext};
