import React, {useContext} from 'react'
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import { Label, Input } from 'reactstrap';
import { FormGroup } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import { phoneCode } from '../../ProData';
export default function GenInfor(props) {
  const { memberInfo, setMemberInfo, calculateProgressiveValues } = useContext(ProductContext);
  const {setTabValue, viewOnly} = props;

  const handleGIAdd=(event)=>{
    event.preventDefault();
    //console.log("Gen Info : ", memberInfo);
    setTabValue("Family")

  }

  const handleGIFocusOut=(event)=>{
    event.preventDefault();
    calculateProgressiveValues(memberInfo);
   // console.log("I m leaving");
  }

  return (
    <div>
         <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">General information</h5>
                  <Row >
            <Col>
                <form > 
                <div className="navbar-bg">
                <FormGroup style={{overflow:"visible"}}>
                <div>
                <Row>
                       <Col xs={3} lg={2}>
                  <Label for="usernameL">Name</Label>
                  </Col>
                  <Col xs={9} lg={4}>
                  <Input
                        type="text"
                        name="username"
                        value={memberInfo.name==="NA"?"":memberInfo.name}
                        disabled={viewOnly}
                        onBlur={(event)=>{handleGIFocusOut(event)}}
                        onChange={(event)=>{setMemberInfo({...memberInfo, name:(event.target.value).toUpperCase()})}}
                      />
                  </Col>
                   <Col xs={3} lg={2}>
                              <Label for="otherName">Other Name</Label>
                              </Col>
                                <Col xs={9} lg={4}>
                                <Input type="text" name="Other Name"
                                value={memberInfo.otherName==="NA"?"":memberInfo.otherName}
                                disabled={viewOnly}
                                //onBlur={(event)=>{handleGIFocusOut(event)}}
                                onChange={(event)=>{setMemberInfo({...memberInfo, otherName:event.target.value})}}
                                >
                              </Input>
                    </Col>
                  </Row>
                  </div>
                    </FormGroup>
                    <FormGroup style={{overflow:"visible"}}>
                    <div>
                    <Row>
                <Col xs={3} lg={2}>
                <Label for="dateOfBirthL">Date Of Birth (Official)</Label>
                </Col>
                  <Col xs={9} lg={4}>
                  <Input type="date" name="dateOfBirth"
                    value={memberInfo.dobOfficial==="NA"?"":memberInfo.dobOfficial}
                    disabled={viewOnly}
                    onBlur={(event)=>{handleGIFocusOut(event)}}
                  onChange={(event)=>{setMemberInfo({...memberInfo, dobOfficial:event.target.value})}}
                      />
                 </Col>
                       <Col xs={3} lg={2}>
                  <Label for="dob">Date of Birth (Actual)</Label>
                  </Col>
                  <Col xs={9} lg={4}>
                  <Input type="date" name="dob"
                      value={memberInfo.dobActual==="NA"?"":memberInfo.dobActual}
                      disabled={viewOnly}
                    //   onBlur={(event)=>{handleGIFocusOut(event)}}
                     onChange={(event)=>{setMemberInfo({...memberInfo, dobActual:event.target.value})}}
                  />
               </Col>
                  </Row>
                  </div>
                </FormGroup>
                <FormGroup style={{overflow:"visible"}}>
                    <div >
                    <Row>
                <Col xs={3} lg={2}>
                <Label for="pob">Place of Birth</Label>
                </Col>
                  <Col xs={9} lg={4}>
                  <Input type="text" name="place of birth"
                      value={memberInfo.placeBirth==="NA"?"":memberInfo.placeBirth}
                      disabled={viewOnly}
                      onBlur={(event)=>{handleGIFocusOut(event)}}
                      onChange={(event)=>{setMemberInfo({...memberInfo, placeBirth:event.target.value})}}
                      />
                            </Col>
                       <Col xs={3} lg={2}>
                  <Label for="diocese">Diocese</Label>
                  </Col>
                  <Col xs={9} lg={4}>
                <div className="form-group"> 
                   <span className="input-group-text" style={{background: "none", fontFamily: "Oswald",  padding: "0 0 0 0"}}>
                      <label id="email">
                      </label>
                      <Input  type="text" aria-describedby="diocese" className="name" id="diocese" 
                        value={memberInfo.diocese==="NA"?"":memberInfo.diocese}
                        disabled={viewOnly}
                        onBlur={(event)=>{handleGIFocusOut(event)}}
                        onChange={(event)=>{setMemberInfo({...memberInfo, diocese:event.target.value})}}
                   /></span>
                      </div>
                      </Col>
                  </Row>
                  </div>
                    </FormGroup>
                <FormGroup style={{overflow:"visible"}}>
                <div>
                <Row>
                       <Col xs={3} lg={2}>
                  <Label for="phoneno">Phone Number</Label>
                  </Col>
                  <Col xs={9} lg={4} className='d-flex'>
                    <Input style={{width:"30%"}} type="select" name="areacode"
                      value={memberInfo.areaCode==="NA"?"+91":memberInfo.areaCode} 
                      onChange={(event)=>{setMemberInfo({...memberInfo, areaCode:event.target.value})}}
                    >
                      {phoneCode.map(item => {
                    return (<option key={item.Code} value={item.Code}>{item.Code}</option>);
                  })}
                  </Input>
                 
                  <Input style={{width:"70%"}}
                        type="text"
                        name="phoneNo"
                        value={memberInfo.phoneNo==="NA"?"":memberInfo.phoneNo}
                        disabled={viewOnly}
                        onBlur={(event)=>{handleGIFocusOut(event)}}
                        onChange={(event)=>{setMemberInfo({...memberInfo, phoneNo:event.target.value})}}
                      />
                  </Col>
                   <Col xs={3} lg={2}>
                              <Label for="email">Email</Label>
                              </Col>
                                <Col xs={9} lg={4}>
                                <Input type="text" name="email"
                                  value={memberInfo.emailID==="NA"?"":memberInfo.emailID}
                                  disabled={viewOnly}
                                  onBlur={(event)=>{handleGIFocusOut(event)}}
                                 onChange={(event)=>{setMemberInfo({...memberInfo, emailID:event.target.value})}}
                                >
                              </Input>
                    </Col>
                  </Row>
                  </div>
                    </FormGroup>
                    <FormGroup style={{overflow:"visible"}}>
                <div>
                <Row>
                       <Col xs={3} lg={2}>
                  <Label for="bloodgroup">Blood Group</Label>
                  </Col>
                  <Col xs={9} lg={4}>
                  <Input
                        type="text"
                        name="bgroup"
                        value={memberInfo.bloodGroup==="NA"?"":memberInfo.bloodGroup}
                        disabled={viewOnly}
                        onBlur={(event)=>{handleGIFocusOut(event)}}
                        onChange={(event)=>{setMemberInfo({...memberInfo, bloodGroup:event.target.value})}}
                      />
                  </Col>
                   <Col xs={3} lg={2}>
                              <Label for="nationality">Nationality</Label>
                              </Col>
                                <Col xs={9} lg={4}>
                                <Input type="text" name="Nationality"
                                value={memberInfo.nationality==="NA"?"":memberInfo.nationality}
                                disabled={viewOnly}
                                onBlur={(event)=>{handleGIFocusOut(event)}}
                                onChange={(event)=>{setMemberInfo({...memberInfo, nationality:event.target.value})}}
                                >
                              </Input>
                    </Col>
                  </Row>
                  </div>
                    </FormGroup>
       </div>
       <FormGroup>
                 <div className="container text-center">
            <div className="d-flex justify-content-around btn ">
                  <button type="submit" onClick={(event)=>{handleGIAdd(event)}}
               className="text-center bg-primary button-approve is-info" style={{width:"80px", border: 'none', borderRadius:'8px', color:'white'}}
             >Next</button>
            </div>
            </div>
                </FormGroup>
                  </form>
            </Col>           
          </Row>
      </Card.Body>
    </Card>
    </div>
  )
}
