import React, {useState, useContext, useEffect} from 'react';
import axios from "axios";
import {currentStatusData, religiousStatusData, gradeData, bookHeaders, missionData} from '../../ProData';
import { Modal, ModalBody, ModalHeader, ModalFooter, Table, Alert,  Row, Col } from 'reactstrap';
import {Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { Form, Image, Card, Button} from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { Auth } from "aws-amplify";
import { ProductContext } from '../../ProductProvider';
import MemberForm from "./MemberForm";
import Preloader from "../../components/Preloader";
import { Routes } from "../../routes";

const config = require('../../config.json');


export default function SearchMember() {
  const { assignValues, alertClose, alertDetails, setAlertDetails, generateDate, memberInfo, orginalEmail, newImage, progressValue, loggedUser, setProgressValue, handleImageError, validateLogin } = useContext(ProductContext);
    const [searchValue, setSearchValue]=useState('');
    const [searchOption, setSearchOption] = useState('Name');
    const [searchResults, setSearchResults] = useState([]);
    const [collapsed, setCollapsed] = useState(true);
    const [profileImage, setProfileImage] = useState('defaultAvatar.jpg');
    const [viewOnly, setViewOnly]= useState(true);
    const [formType, setFormType]=useState("View");
    const [missionName, setMissionName]= useState('Madurai New');
    const [loaded, setLoaded] = useState(true);
    const [newProfileImage, setNewProfileImage]=useState('defaultAvatar.jpg');
    const csvLinkEl = React.createRef();

   // const [newImage, setNewImage]=useState('');

   useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }
   // console.log(batchYear);
  },[loggedUser])


    const handleSearch =async(event)=>{
        event.preventDefault();
        alertClose();
        //console.log("Value :", searchValue);
        try {
            if(searchValue==="")
            {
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Enter the data to Search!!!"
                }); 
                window.scrollTo(0,0); 
            }
            else{    
              //showLoader();
              setLoaded(false);
                const session = await Auth.currentSession();
                const access_token=session.idToken.jwtToken;
                const familyname="SJMDU";

                //const familyname=loggedUser.provinceName;
                //const searchOption="User ID";
                const res = await axios.get(`${config.api.invokeUrl}/global_search?searchValue=${searchValue}&searchOption=${searchOption}&familyName=${familyname}&missionName=${missionName}`,{
                    headers:{
                        Authorization: access_token,
                        'x-api-key': config.api.key
                    }
                });
                //console.log("res DAta : ", res);
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: res.data.length + " - Users found"
                }); 
                window.scrollTo(0,0); 
               /* var i=0;
                while(i<res.data.length){
                //  console.log("before value : ", res.data[i].Book_Classification_No);
                 const tempClassValue= [...bookClassification].filter(searchresult =>searchresult.value===res.data[i].Book_Classification_No);
                 res.data[i].Book_Classification_No=tempClassValue[0].name;
                 //console.log("temp class vale : ", tempClassValue);
                 //console.log("After update : ", res.data[i].Book_Classification_No);
    
                 i++;
                }*/
                
                setSearchResults(res.data);
                setLoaded(true);
                //hideLoader();
            }
        } catch (error) {
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);

            window.scrollTo(0,0); 
        }
    }
  const toggle = () => {
   //   console.log("clicked");
   
        setCollapsed(!collapsed);
    }
    const handleProjectView=(event, item)=>{
      event.preventDefault();
      alertClose();
     // console.log("item :", item);
     if(item.profileImage!=="NA"){
      setProfileImage(`${config.s3.imageURL}${item.profileImage}`);
     }
      assignValues(item);
     // setMyProfileImage(`${config.s3.imageURL}${tImageName}`);

    //  setProfileImage(`${config.s3.imageURL}${item.profileImage}`);
      setProgressValue(item.progressValue);
      setViewOnly(true);
      setFormType("View");
      toggle(); 
      
  }
  const handleProjectEdit=(event, item)=>{
      event.preventDefault();
      alertClose();
      if(item.profileImage!=="NA"){
        setProfileImage(`${config.s3.imageURL}${item.profileImage}`);
       }
        assignValues(item);
      //setProfileImage(`${config.s3.imageURL}${item.profileImage}`);
      setProgressValue(item.progressValue);
      if(item.profileImage){
      setNewProfileImage(item.profileImage);
      }
      setViewOnly(false);
      setFormType("Edit");
      toggle();
  }

  const postOnS3 = async(fileName)=>{
    try{
        //  console.log("NewImage : ", newImage);
      const newFileName=fileName+".jpeg";
      //console.log("newfilename :", newFileName);
      
      var file = newImage;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
     // console.log("New file : ", newFile);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;   
      const bucketName=config.s3.bucketName;
      await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
     // await axios.get(`${config.api.invokeURL}/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
           headers:{
               Authorization: access_token,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
          // console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
           //  console.log("Res : ", res);
             if(res.status===200){
              setNewProfileImage(newFileName);
              //  fetchGalleryImages();
                // setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                // var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
             }
        }).catch((error)=>{
            //console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            return "Failure";
         // setDispMsg(error.message);
         // hideLoader();
       })
    
    }catch(error){
    //  console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    return "Failure";
    }
    return "Success";
  
  }

  const handleDownload =async()=>{
    csvLinkEl.current.link.click();
  }

  const handleUserEdit = async(event)=>{
   alertClose();
    event.preventDefault();
    try {
      if(memberInfo.name===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Name is a mandatory field - Enter Name under General Tab"
      }); 
      window.scrollTo(0,0);
    }else if(memberInfo.name.trim().length<4){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Enter Full Name - Name cannot be less than 4 characters!"
    }); 
    window.scrollTo(0,0);
    }else if (memberInfo.diocese===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Diocese cannot be empty. Enter the Diocese details under General Tab"
    }); 
    window.scrollTo(0,0);
    }else if (memberInfo.dobOfficial===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Official Date of Birth cannot be empty. Enter the Official Date of Birth under General Tab"
    }); 
    }else if (memberInfo.entranceDate===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Entrance date cannot be empty. Enter the Enterance Date under Society Tab"
    }); 
    window.scrollTo(0,0);
    }else if (memberInfo.entrancePlace===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Entrance place cannot be empty. Enter the Enterance place details under Society Tab"
    }); 
    window.scrollTo(0,0);
    }
      else{
          //const userid= await generateUserId();
          setLoaded(false);
          const userid=memberInfo.userId;
          var profileImage="defaultAvatar.jpg";
          var imageUploadStatus="Failure";
          if(newImage){
            imageUploadStatus= await postOnS3(userid); 
            if(imageUploadStatus==="Success"){
              profileImage=userid+".jpeg";
            } 
          }
          const tdate=await generateDate();
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          const { name, otherName, missionName, dobOfficial,dobActual,placeBirth,diocese,nationality, 
            bloodGroup,emailID, areaCode, phoneNo, entranceDate, entrancePlace,firstVowDate,firstVowPlace,ordinationDate, 
            ordinationPlace,ordainingBishop, tertainshipPlace,tertainshipEndDate, tertainInstructor,finalVowsDate, 
            finalVowsPlace, grade, currentStatus, religiousStatus, provinceEntered,dateLeft, dateTransferred, 
            transferredProvicialiateName,deathDate, deathPlace,burialPlace, familyDetails, studyDetails, 
            formationDetails, communityDetails, languageDetails}=memberInfo;
         /* "provNameJoined" : book_data[9],
          "ascriDate" : book_data[10],
          "details" : book_data[20]*/
          
          const params = {
              "communityName": loggedUser.provinceName,
              "userId": userid,
              "username":  name,
              "missionName":missionName,
              "currentStatus": currentStatus,
              "dobOfficial": dobOfficial,
              "placeOfBirth":placeBirth,
              "areaCode":areaCode,
              "phoneNo": phoneNo,    
              "emailId": emailID,
              "dateOrdain":ordinationDate,
              "lastVow": finalVowsDate,
              "Grade": grade,
              "religousStatus": religiousStatus,
            //  "details": user.details,
              "nationality":nationality,
              "dateLeft":dateLeft,
              "dateTransferred":dateTransferred,
              "provTransferred":transferredProvicialiateName,
              "dateOfDeath":deathDate,
              "placeOfDeath":deathPlace,
              "placeOfBurial":burialPlace,
              "otherName":otherName,
              "dobActual":dobActual,
              "diocese":diocese,
              "bloodGroup":bloodGroup,
              "dateJoined":entranceDate,
              "entrancePlace":entrancePlace,
              "firstVowDate":firstVowDate,
              "firstVowPlace":firstVowPlace,
              "ordinationPlace":ordinationPlace,
              "ordainingBishop":ordainingBishop,
              "tertainshipPlace":tertainshipPlace,
              "tertainshipEndDate":tertainshipEndDate,
              "tertainInstructor":tertainInstructor,
              "finalVowsPlace":finalVowsPlace,
              "provinceEntered":provinceEntered,
              "familyDetails":familyDetails,
              "studyDetails":studyDetails,
              "progressValue":progressValue,
              "profileImage":profileImage,
              "formationDetails":formationDetails,
              "communityDetails":communityDetails,
              "languageDetails":languageDetails,
              "updatedBy":loggedUser.nickname,
              "updatedOn":tdate
          };
          console.log("Params : ", params);
      await axios.patch(`${config.api.invokeUrl}/user_datails/${userid}`, params,{
      headers: {
        Authorization: access_token,
        'x-api-key':config.api.key
      }})
      .then(async(response)=>{
        console.log("Response : ", response);
        if(response.status===202){
          await handleSearch(event);
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: "Congratulations! "+memberInfo.name+ " details Updated successfully!"
          });  
          window.scrollTo(0,0);
        }
        setLoaded(true);
      })
      .catch((error)=>{
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Error Occured : " + error.message
        }); 
        window.scrollTo(0,0);
        setLoaded(true);
      })
    }
  }catch (err) {
        setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message:"Error Occured - " + err.message
  }); 
  window.scrollTo(0,0);
  setLoaded(true);
  }
  }

  return (
    <div className='navbar-search'>
       <Preloader show={loaded ? false : true} />
         <Breadcrumb>
    <BreadcrumbItem>
      <Link to={Routes.LandingPage.path}>
        Home
      </Link>
    </BreadcrumbItem>
    <BreadcrumbItem >
      Members
    </BreadcrumbItem>
    <BreadcrumbItem >
      Search Members
    </BreadcrumbItem>
  </Breadcrumb>
     <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
  <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
            <ModalHeader toggle={toggle}>
              {formType} - {memberInfo.name} 
            </ModalHeader>
            <ModalBody>
           
                <MemberForm formType={formType} profileImage={profileImage} setProfileImage={setProfileImage} viewOnly={viewOnly}/>
                <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
                  {alertDetails.message}
            </Alert>
            </ModalBody>
            <ModalFooter>
           {formType==="Edit"? <Button onClick={(event)=>{handleUserEdit(event)}}>
        Update
      </Button>:null}
      {' '}
      <Button onClick={toggle}>
        Close
      </Button>
    </ModalFooter>
  </Modal>
          <div  >
          <Card border="light" className=" bg-white shadow-sm mb-4 p-2">
      
      <h5 className="text-center p-3 mb-4">User Search</h5>
            <Form  className='navbar-search d-flex justify-content-center' >

           
          <Form.Group  id="missionName" >
               
                <Form.Select className="search-mem-form bg-primary"  defaultValue={missionName}  onChange={(event)=>{setMissionName(event.target.value)}} >
              
                 {missionData.map(item => {
              return ( <option   key={item.name} value={item.name}>{item.name}</option>
                );
            })}
               
               
               
                </Form.Select>
              </Form.Group>
   
       {searchOption==="Grade"?
   
   <select className="input-phonecode-s"  style={{borderRadius:'7px',  textAlign:'center'}} value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}>
   {gradeData.map(item => {
     return (<option key={item.name} value={item.name}>{item.name}</option>);
   })}</select>:
   searchOption==="Mission"?
   <select className="input-phonecode-s" style={{borderRadius:'7px',  textAlign:'center'}} value={searchValue } onChange={(event)=>{setSearchValue(event.target.value)}}>
     
   {missionData.map(item => {
     return (<option key={item.name} value={item.name}>{item.name}</option>);
   })}</select>:
   searchOption==="RStatus"?
   <select className="input-phonecode-s"  style={{borderRadius:'7px',  textAlign:'center'}} value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}>
   {religiousStatusData.map(item => {
     return (<option key={item.name}  value={item.name}>{item.name}</option>);
   })}</select>:
   searchOption==="CStatus"?
   <select className="input-phonecode-s"  style={{borderRadius:'7px',  textAlign:'center'}} value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}>
   {currentStatusData.map(item => {
     return (<option key={item.name} value={item.name}>{item.name}</option>);
   })}</select>:
     <input type="search"   placeholder="Search your details here..."   
   value={searchValue} 
   onChange={(event)=>{setSearchValue((event.target.value).toUpperCase())}}
   
   
   />
   
   }
      
        
  
            <button type="submit" 
               className="text-center bg-primary button-approve is-info" style={{width:"80px", border: 'none', borderRadius:'8px', color:'white'}} 
               onClick={(event)=>{handleSearch(event)}}
              
             ><i className='fa fa-search'></i></button>
            

</Form>
             
{/*}
                <Row >
          <Col  xs={4} md={4} xl={2} >
          <Form.Group id="gender">
               
                <Form.Select defaultValue="UserID" onChange={(event)=>{setSearchOption(event.target.value)}} >
                {searchData.map(item => {
              return (<option key={item.name} value={item.value}>{item.name}</option>);
            })}</Form.Select>
              </Form.Group>
          </Col>
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search"  onChange={(event)=>{setSearchValue(event.target.value)}}/>
            </InputGroup>
          </Col>
         
        </Row>

          */}
          <div className='d-flex justify-content-center' style={{paddingTop:'10px'}}>
         
            <input
                        type="radio"
                        id="Name"
                        value="Name"
                        name="searchoption"
                        checked={searchOption==="Name"}
                        onChange={(event)=>{setSearchValue('');setSearchOption(event.target.value)}}
                    />
    
                   <span style={{padding:'8px'}}>Name</span>  
           
            <input
                        type="radio"
                        name="searchoption"
                        id="CStatus"
                        value="CStatus"
                        checked={searchOption==="CStatus"}
                        onChange={(event)=>{setSearchValue("Member");setSearchOption(event.target.value)}}
                        />
    
    <span style={{padding:'8px'}}>Current Status</span>  
            
           
            <input
                        type="radio"
                        name="searchoption"
                        id="RStatus"
                        value="RStatus"
                        checked={searchOption==="RStatus"}
                        onChange={(event)=>{setSearchValue("Brother");setSearchOption(event.target.value)}}
                        />
    
                   <span style={{padding:'8px'}}>Religious Status</span>  
         
            <input
                        type="radio"
                        name="searchoption"
                        id="Grade"
                        value="Grade"
                        checked={searchOption==="Grade"}
                        onChange={(event)=>{setSearchValue('Scholastic Novice');setSearchOption(event.target.value)}}
                    />
    
    <span style={{padding:'8px'}}>Grade</span>  
            
            
          </div>
  
 {/*                
<div className='d-flex justify-content-around p-4' >
          
        
          

                   <div >
                   
                   <input
                        type="radio"
                        name="searchoption"
                        id="CStatus"
                        value="CStatus"
                        checked={searchOption==="CStatus"}
                        onChange={(event)=>{setSearchValue("Member");setSearchOption(event.target.value)}}
                        />
    
                    Current Status
                   </div>
                   <div>
                   
                   <input
                        type="radio"
                        name="searchoption"
                        id="RStatus"
                        value="RStatus"
                        checked={searchOption==="RStatus"}
                        onChange={(event)=>{setSearchValue("Brother");setSearchOption(event.target.value)}}
                        />
    
                    Religious Status
                   </div>
                   <div>
                
                   <input
                        type="radio"
                        name="searchoption"
                        id="Grade"
                        value="Grade"
                        checked={searchOption==="Grade"}
                        onChange={(event)=>{setSearchValue('Scholastic Novice');setSearchOption(event.target.value)}}
                    />
                     Grade
    </div>  
            </div>
        */}
            
    

       
             
           
          
            {searchResults.length>0?
<div style={{textAlign:"right", color:"blue", paddingTop:"20px"}}>
<FontAwesomeIcon icon={faFileDownload} className="me-2" onClick={(event)=>{handleDownload(event)}}/>
 <b>Download File  </b>
<FontAwesomeIcon icon={faFileDownload} className="me-2" onClick={(event)=>{handleDownload(event)}}/>
<CSVLink
headers={bookHeaders}
filename="download.csv"
data={searchResults}
ref={csvLinkEl}
/></div>:null }
            </Card>
          </div>

          {searchResults.length>0?<div>
                <Table hover responsive bordered >
              <thead>
                <tr>
                <th>Action</th>
                  <th>Name</th>
                  <th>Date Joined</th>
                  <th>Current Status</th>
                  <th>Religious Status</th>
                  <th>Grade</th>
                </tr>
              </thead>      
              <tbody>
        {searchResults.map((searchresult,index) =>(
        <tr key={index} style={{verticalAlign:'middle'}}>
        <td >
          <FontAwesomeIcon icon={faEye} className="me-2" onClick={(event)=>{handleProjectView(event, searchresult)}}/>
          <FontAwesomeIcon icon={faEdit} className="me-2" onClick={(event)=>{handleProjectEdit(event, searchresult)}}/>
        </td>
          <th   scope="row" key={searchresult.userId}> {searchresult.profileImage==="NA"?<div >{searchresult.username}</div>:<div  className='d-flex justify-content-start'><Image src={`${config.s3.imageURL}${searchresult.userId}`+".jpeg"} className="user-avatar md-avatar rounded-circle "  onError={handleImageError}/><span style={{paddingLeft:'10px', paddingTop:'15px'}}>{searchresult.username}</span></div>}</th>
          <td>{searchresult.dateJoined==="NA"?null:<div>{searchresult.dateJoined}</div>}</td>
          <td>{searchresult.currentStatus==="NA"?null:<div>{searchresult.currentStatus}</div>}</td>
          <td>{searchresult.religousStatus==="NA"?null:<div>{searchresult.religousStatus}</div>}</td>
          <td>{searchresult.Grade==="NA"?null:<div>{searchresult.Grade}</div>}</td>
        </tr>))}
        </tbody></Table>
        </div>:<div className="tile notification is-warning"></div>} 
    </div>
  )
}