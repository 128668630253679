import React,{useContext, useEffect, useState} from 'react';
import { Col, Row, Card, Button } from '@themesberg/react-bootstrap';
import { Input, Table } from 'reactstrap';
import { FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faAdd , faTrash} from "@fortawesome/free-solid-svg-icons";
import {ProductContext} from "../../ProductProvider";
import { Alert } from "reactstrap";
import {familyRelationship, phoneCode} from '../../ProData';
import { nanoid } from 'nanoid'


export default function FamInfo(props) {
  const { setSaveFamilyData, alertDetails, setAlertDetails, alertClose, memberInfo, setMemberInfo, calculateProgressiveValues } = useContext(ProductContext);
  const [localFD, setLocalFD]=useState({id:'1',name:"",relationship:"Father",areaCode:"+91", phoneNo:"", eMail:"", address:""});
  const {setTabValue, viewOnly} = props;

  useEffect(()=>{
    alertClose();
    generateNewID();
  },[])

  const generateNewID=()=>{
    const idgen=nanoid(10);
    setLocalFD({...localFD,
      id:idgen,name:"",relationship:"Father",areaCode:"+91", phoneNo:"", eMail:"", address:""});
  }


  const handleMemberAdd =async(event)=>{
    event.preventDefault();
    if(localFD.name===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Name cannot be empty"
    }); 
    }else{
     // setMemberFD([...memberFD, localFD]);
      await setMemberInfo({...memberInfo,
      familyDetails:[...memberInfo.familyDetails, localFD]});
      //resetValues();
      generateNewID();
      calculateProgressiveValues(memberInfo);
    }

  }

  const handleFamilySave=(event)=>{
    event.preventDefault();
    setSaveFamilyData(true);
    setTabValue("Society");

  }

  const handleRemoveMember=async(event, member)=>{
    event.preventDefault();
    //console.log("item : ", item);
     // let tempCart = [...memberFD];
     let tempCart=[...memberInfo.familyDetails];
      tempCart = tempCart.filter(item => item.id !==member.id);
     // console.log("temp cart :", tempCart);
     //setMemberFD([...tempCart]);
     await setMemberInfo({
       ...memberInfo,
       familyDetails:[...tempCart]
     });
     calculateProgressiveValues(memberInfo);
     generateNewID();
  }


  return (
    <div>
         <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Family information</h5>
        <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
<h6>Add Family Details</h6>
                  <Row >
            <Col>
              
                <form > 
                
                {!viewOnly? <div className="navbar-bg" style={{marginBottom:'20px'}}>
                <FormGroup style={{overflow:"visible"}}>
               <div>
                <Row>
                  <Col xs={9} lg={4}>
                  <Input
                        type="text"
                        name="familyMemberName"
                        placeholder="Family member Name"
                        value={localFD.name}
                        onChange={(event)=>{setLocalFD({...localFD,
                        name:event.target.value})}}
                      />
                  </Col>
                    <Col xs={9} lg={3}>
                    <Input type="select" name="familyRelationship"
                      value={localFD.relationship} 
                      onChange={(event)=>{setLocalFD({...localFD, relationship:(event.target.value)})}}
                    >
                      {familyRelationship.map(item => {
                    return (<option key={item.name} value={item.name}>{item.name}</option>);
                  })}
                  </Input>
                    </Col>
                    <Col xs={9} lg={1}>
                    <Input type="select" name="areacode"
                      value={localFD.areaCode} 
                      onChange={(event)=>{setLocalFD({...localFD, areaCode:(event.target.value)})}}
                    >
                      {phoneCode.map(item => {
                    return (<option key={item.Code} value={item.Code}>{item.Code}</option>);
                  })}
                  </Input>
                 </Col>

                  <Col xs={9} lg={3}>
                  <Input type="number" name="phoneno" placeholder='Phone Number'
                                          value={localFD.phoneNo}
                       onChange={(event)=>{setLocalFD({...localFD,
                        phoneNo:event.target.value})}}
                      />
                 </Col>
                 </Row>
                 <Row>
                 <Col xs={9} lg={4}>
                  <Input type="email" name="email" placeholder='Email'
                                          value={localFD.eMail}
                       onChange={(event)=>{setLocalFD({...localFD,
                        eMail:event.target.value})}}
                      />
                 </Col>
                 <Col xs={9} lg={6}>
                  <Input type="text" name="address" placeholder='Address'
                                          value={localFD.address}
                       onChange={(event)=>{setLocalFD({...localFD,
                        address:event.target.value})}}
                      />
                 </Col>


                 <Col xs={9} lg={2}>
                 <Button title="Add User" icon={faAdd} onClick={(event)=>{handleMemberAdd(event)}} >ADD</Button>
                 </Col>
                  </Row>
                  </div>
                    </FormGroup>
       </div> :null}  
       <Table hover responsive>
  <thead>
    <tr>
      <th>#</th>
      <th>Name</th>
      <th>Relationship</th>
      <th>Country Code</th>
      <th>Phone Number</th>
      <th>Email</th>
      <th>Address</th>

    </tr>
  </thead>
  <tbody>
  {memberInfo.familyDetails && memberInfo.familyDetails.map((item, index)=>
    <tr key={index}>
      <th scope="row">{index+1}</th>
      <td>{item.name}</td>
      <td>{item.relationship}</td>
      <td>{item.areaCode}</td>
      <td>{item.phoneNo}</td>
      <td>{item.eMail}</td>
      <td>{item.address}</td>

     {!viewOnly?<td><FontAwesomeIcon icon={faTrash} className="me-2" onClick={(event)=>{handleRemoveMember(event, item)}}/></td>:null}
    </tr>
  )}
  </tbody>
</Table>
       <FormGroup>
                 <div className="container text-center">
            <div className="d-flex justify-content-around btn ">
           <button type="submit" onClick={(event)=>{handleFamilySave(event)}}
               className="text-center bg-primary button-approve is-info" style={{width:"80px", border: 'none', borderRadius:'8px', color:'white'}}
             >Next</button>
            </div>
            </div>
                </FormGroup>
                  </form>
            </Col>           
          </Row>
      </Card.Body>
    </Card>
    </div>
  )
}
