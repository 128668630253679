import React, { useState, useContext, useEffect } from "react";
import {ProductContext} from '../../ProductProvider';
import {Breadcrumb, BreadcrumbItem, Alert, Button, Table } from "reactstrap";
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Image} from '@themesberg/react-bootstrap';
import Preloader from "../../components/Preloader";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import MemberForm from "./MemberForm";
import axios from "axios";
import { Auth } from "aws-amplify";
const config = require('../../config.json');

export default () => {
  const { resetValues, loggedUser,newImage, progressValue, memberInfo, setMemberInfo, handleImageError, generateDate, alertClose, alertDetails, setAlertDetails,validateLogin } = useContext(ProductContext);
  const [profileImage, setProfileImage] = useState('defaultAvatar.jpg');
  const [loaded, setLoaded] = useState(true);
  const [newProfileImage, setNewProfileImage]=useState('defaultAvatar.jpg')
  const [collapsed, setCollapsed] = useState(true);
  const [duplicateRecords, setDuplicateRecords]=useState([]);
  const [isDuplicate, setIsDuplicate]=useState(true);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      resetValues();
    }else{
    validateLogin();
    alertClose();
    resetValues();
    }
    return()=>{
      alertClose();
      resetValues();
    }
   // console.log(batchYear);
  },[loggedUser])
  
  const postOnS3 = async(fileName)=>{
    try{
        //  console.log("NewImage : ", newImage);
      const newFileName=fileName+".jpeg";
      //console.log("newfilename :", newFileName);
      
      var file = newImage;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
     // console.log("New file : ", newFile);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;   
      const bucketName=config.s3.bucketName;
      await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
     // await axios.get(`${config.api.invokeURL}/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
           headers:{
               Authorization: access_token,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
          // console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
           //  console.log("Res : ", res);
             if(res.status===200){
              setNewProfileImage(newFileName);
              //  fetchGalleryImages();
                // setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                // var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
             }
        }).catch((error)=>{
            //console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            return "Failed";
         // setDispMsg(error.message);
         // hideLoader();
       })
    
    }catch(error){
    //  console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    return "Failed";
    }
  return "Success";
  }


const handleUserAdd = async (event,tisDuplicate) => {
  event.preventDefault();
  try {
      if(memberInfo.name===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Name is a mandatory field - Enter Name under General Tab"
        }); 
        window.scrollTo(0,0); 
      }else if(memberInfo.name.trim().length<4){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Enter Full Name - Name cannot be less than 4 characters!"
      }); 
      window.scrollTo(0,0); 
      }else if (memberInfo.diocese===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Diocese cannot be empty. Enter the Diocese details under General Tab"
      }); 
      window.scrollTo(0,0); 
      }else if (memberInfo.dobOfficial===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Official Date of Birth cannot be empty. Enter the Official Date of Birth under General Tab"
      }); 
      window.scrollTo(0,0); 
      }else if (memberInfo.entranceDate===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Entrance date cannot be empty. Enter the Enterance Date under Society Tab"
      }); 
      window.scrollTo(0,0); 
      }else if (memberInfo.entrancePlace===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Entrance place cannot be empty. Enter the Enterance place details under Society Tab"
      }); 
      window.scrollTo(0,0); 
      }
      else{
        setLoaded(false);
        var userid="";
        var profileImage="defaultAvatar.jpg";
        if(tisDuplicate){
       //   console.log("generated user id");
        userid= await generateUserId();
        if(newImage){
          var uploadStatus="Failed";
          uploadStatus= await postOnS3(userid);  
          if(uploadStatus==="Success"){
            profileImage=userid+".jpeg";
          }
        }
      }else{
        userid=memberInfo.userId;
      }
        const tdate=await generateDate();
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          //const userid="tuserId";
          //console.log("userid = ", userid);
          const { name, otherName, missionName, dobOfficial,dobActual,placeBirth,diocese,nationality, bloodGroup,emailID, areaCode, phoneNo, entranceDate, entrancePlace,firstVowDate,firstVowPlace,ordinationDate, ordinationPlace,ordainingBishop, tertainshipPlace,tertainshipEndDate, tertainInstructor,finalVowsDate, finalVowsPlace, grade, currentStatus, religiousStatus, provinceEntered,dateLeft, dateTransferred, transferredProvicialiateName,deathDate, deathPlace,burialPlace, familyDetails, studyDetails, formationDetails, communityDetails, languageDetails}=memberInfo;

          /*"provNameJoined" : book_data[9],
          "ascriDate" : book_data[10],
          "details" : book_data[20],*/
          const params = {
              "communityName": loggedUser.provinceName,
              "userId": userid,
              "username":  name,
              "missionName":missionName,
              "currentStatus": currentStatus,
              "dobOfficial": dobOfficial,
              "placeOfBirth":placeBirth,
              "areaCode":areaCode,
              "phoneNo": phoneNo,    
              "emailId": emailID,
              "dateOrdain":ordinationDate,
              "lastVow": finalVowsDate,
              "Grade": grade,
              "religousStatus": religiousStatus,
            //  "details": user.details,
              "nationality":nationality,
              "dateLeft":dateLeft,
              "dateTransferred":dateTransferred,
              "provTransferred":transferredProvicialiateName,
              "dateOfDeath":deathDate,
              "placeOfDeath":deathPlace,
              "placeOfBurial":burialPlace,
              "otherName":otherName,
              "dobActual":dobActual,
              "diocese":diocese,
              "bloodGroup":bloodGroup,
              "dateJoined":entranceDate,
              "entrancePlace":entrancePlace,
              "firstVowDate":firstVowDate,
              "firstVowPlace":firstVowPlace,
              "ordinationPlace":ordinationPlace,
              "ordainingBishop":ordainingBishop,
              "tertainshipPlace":tertainshipPlace,
              "tertainshipEndDate":tertainshipEndDate,
              "tertainInstructor":tertainInstructor,
              "finalVowsPlace":finalVowsPlace,
              "provinceEntered":provinceEntered,
              "familyDetails":familyDetails,
              "studyDetails":studyDetails,
              "languageDetails":languageDetails,
              "formationDetails":formationDetails,
              "communityDetails":communityDetails,
              "progressValue":progressValue,
              "profileImage":profileImage,
              "validateDuplicate":tisDuplicate,
              "updatedBy":loggedUser.nickname,
              "updatedOn": tdate
           };

         // console.log(" Params : ", params);
    await axios.post(`${config.api.invokeUrl}/user_datails/${userid}`, params,{
      headers: {
        Authorization: access_token,
        'x-api-key':config.api.key
      }})
      .then(async(response)=>{
      //  console.log("response : ", response); 
        if(response.status===210){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: memberInfo.emailID + " - Email Address already exists."
        }); 
        window.scrollTo(0,0); 
         // handleCancelClick(event);
     //    resetValues();
         setLoaded(true);
        }else if(response.status===220){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: response.data.length + " - Similar records found. Kindly validate."
        }); 
        window.scrollTo(0,0); 
      //  console.log(response.data.length);
        setDuplicateRecords(response.data);
        setLoaded(true);
        toggle();

         // handleCancelClick(event);
      //   resetValues();
        }else if(response.status===200){
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: memberInfo.name + " - Added without Login Account"
        }); 
        window.scrollTo(0,0); 
         // handleCancelClick(event);
         setIsDuplicate(true);
         resetValues();
         setLoaded(true);
        }else if(response.status===251){
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: memberInfo.name +" - Added and Login Account Created Successfully!"
        }); 
        window.scrollTo(0,0); 
         // handleCancelClick(event);
         setIsDuplicate(true);
         resetValues();
         setLoaded(true);
        }else{
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured. Try Again"
        }); 
        window.scrollTo(0,0); 
         // handleCancelClick(event);
       //  resetValues();
         setLoaded(true);
      }
      }).catch((error)=>{
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Error Occured : " + error.message
        }); 
        window.scrollTo(0,0); 
        setLoaded(true);
      })
    }
  
  }catch (err) {
        setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message:"Error Occured - " + err.message
  }); 
  window.scrollTo(0,0); 
  setLoaded(true);

  }

}

const handleUserContinue=async(event)=>{
  event.preventDefault();
  toggle();
  await setIsDuplicate(false);
  setDuplicateRecords([]);
  //console.log("test");
  await handleUserAdd(event,false);
  //console.log("testing");
}

const toggle = () => {
  //   console.log("clicked");
  
       setCollapsed(!collapsed);
   }

const generateUserId = async () => {
  const current_datetime = new Date();
  const tusername=memberInfo.name.trim().replace(/\s/g, "");
  const tempUserId= tusername.substring(0,4);
  const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
  var date_month = (current_datetime.getMonth() + 1).toString();
  var month_value="";
  date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
  var date_date = (current_datetime.getDate().toString());
  var date_value="";
  date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
  var date_hours = (current_datetime.getHours().toString());
  var hours_value="";
  date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
  var date_minutes = (current_datetime.getMinutes().toString());
  var minutes_value="";
  date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
  const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value);
  //setUserId(accountNo);
  setMemberInfo({...memberInfo, userId:accountNo});
  return accountNo;
  //console.log("acc : ", accountNo);
}

  return (
    <>
     <div>
         <Preloader show={loaded ? false : true} />
         <Breadcrumb>
    <BreadcrumbItem>
      <Link to={Routes.LandingPage.path}>
        Home
      </Link>
    </BreadcrumbItem>
    <BreadcrumbItem >
      Member
    </BreadcrumbItem>
    <BreadcrumbItem >
      Add Member
    </BreadcrumbItem>
  </Breadcrumb>
  <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>

  <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
            <ModalHeader toggle={toggle}>
              {duplicateRecords.length} - Similar Records Found - Confirm to Add the user!
            </ModalHeader>
            <ModalBody>
              <div>
            {duplicateRecords.length>0?<div>
                <Table hover responsive bordered>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Date Joined</th>
                  <th>Current Status</th>
                  <th>Religious Status</th>
                  <th>Grade</th>
                </tr>
              </thead>      
              <tbody>
        {duplicateRecords.map((searchresult,index) =>(
        <tr key={index}>       
          <th scope="row" key={searchresult.userId}> <span><Image src={`${config.s3.imageURL}${searchresult.profileImage}`} className="user-avatar md-avatar rounded-circle" onError={handleImageError}/>{searchresult.username}</span></th>
          <td>{searchresult.dateJoined}</td>
          <td>{searchresult.currentStatus}</td>
          <td>{searchresult.religousStatus}</td>
          <td>{searchresult.Grade}</td>
        </tr>))}
      
        </tbody></Table>

            </div>:null} 

                {/*<AddImge setImageFile={setImageFile} profileImage={profileImage} /> */}
               </div>
            </ModalBody>
            <ModalFooter>
          <Button onClick={(event)=>{handleUserContinue(event)}}>
        Confirm
      </Button>
      {' '}
      <Button onClick={toggle}>
        Close
      </Button>
    </ModalFooter>
            </Modal>


        {/* profile View*/}
        <MemberForm formType="Add Member" profileImage={profileImage} setProfileImage={setProfileImage} viewOnly={false}/>
        <Button className="container justify-content-md-center" onClick={(event)=>{handleUserAdd(event,true)}}>Add</Button>  
          
      </div>
    { /* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4" >
      {/*  <div className="d-block mb-4 mb-md-0 align-items-center">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Catalogue</Breadcrumb.Item>
            <Breadcrumb.Item active>Add User</Breadcrumb.Item>
          </Breadcrumb>
          <h4 >ADD USER</h4>
          <p className="mb-0"></p>
        </div>
  */}
  {/*}    <h4 >ADD USER</h4>
      </div>
      <ProfileImage fileName={user.userId} setUser={setUser} setProfileImage={setProfileImage} profileImage={profileImage} user={user} />
      <div className='container'><ProgressBarStatus  progressValue={progressValue} dispValue="Profile"/>
           </div>

      <div className="table-settings mb-4">
      <Tabs defaultActiveKey="Home" id="uncontrolled-tab-example"       activeKey={tabValue}
      onSelect={(k) => setTabValue(k)}
className="mb-3">
    
    <Tab eventKey="Home" title="General Information">
   <GenInfor setTabValue={setTabValue} />
  </Tab>
  <Tab eventKey="Family" title="Family Information">
   <FamInfo setTabValue={setTabValue}/>
  </Tab>
  <Tab eventKey="Society" title="Society Information">
    <SocSJInfo setTabValue={setTabValue}/>
  </Tab>
  <Tab eventKey="Studies" title="Studies" >
    <AcademicInfo setTabValue={setTabValue}/>
  </Tab>

  <Tab eventKey="other" title="Other" >
    
  </Tab>

</Tabs>
</div>*/}

     
    </>
  );
};
