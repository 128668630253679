import React, {useContext, useState, useEffect} from 'react';
import { Col, Row, Card, Button, Form } from '@themesberg/react-bootstrap';
import { Input, Table , Alert} from 'reactstrap';
import { FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faAdd , faTrash} from "@fortawesome/free-solid-svg-icons";
import {ProductContext} from "../../ProductProvider";
import { nanoid } from 'nanoid';


export default function AcademicInfo(props) {
  const { alertDetails, setAlertDetails, alertClose, memberInfo, setMemberInfo,calculateProgressiveValues } = useContext(ProductContext);
  const [localStudy, setLocalStudy]=useState({id:'1',name:"",place:"",startYear:"", endYear:"", degree:""});
  const {setTabValue, viewOnly } = props;

  useEffect(()=>{
    alertClose();
    generateNewID();
  },[])

  const generateNewID=()=>{
    const idgen=nanoid(10);
    setLocalStudy({...localStudy,
      id:idgen,
      name:'',place:'',startYear:'', endYear:'', degree:''});
  }


  const handleStudyAdd =async(event)=>{
    event.preventDefault();
    if(localStudy.name===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Name cannot be empty"
    }); 
    }else{
      //setStudyDetails([...studyDetails, localStudy]);
      await setMemberInfo({...memberInfo,
        studyDetails:[...memberInfo.studyDetails, localStudy]});
        //await resetValues();
        await generateNewID();
        calculateProgressiveValues(memberInfo);
    }
  }

  const handleRemoveStudy=async(event, member)=>{
    event.preventDefault();
    //console.log("item : ", item);
      let tempCart = [...memberInfo.studyDetails];
      tempCart = tempCart.filter(item => item.id !==member.id);
     // console.log("temp cart :", tempCart);
     await setMemberInfo({
      ...memberInfo,
      studyDetails:[...tempCart]
    });
    generateNewID();
    calculateProgressiveValues(memberInfo);
    // setStudyDetails([...tempCart]);
  }

const handleAcadamicsNext=(event)=>{
  event.preventDefault();
 // setSaveFamilyData(true);
  setTabValue("Formation");
}

  return (
    <div>
         <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Academic information</h5>
        <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
    </Alert>
    <h6>Add Details</h6>
    <Row >
            <Col>
                <form > 
                  {!viewOnly?
                <div className="navbar-bg" style={{marginBottom:'20px'}}>
                <FormGroup style={{overflow:"visible"}}>
                <div>
                <Row>
            <Col xs={9} lg={3}>
            <Form.Label>Institution Name </Form.Label>
                  <Input
                        type="text"
                        name="institutionname"
                        value={localStudy.name}
                        onChange={(event)=>{setLocalStudy({...localStudy,
                        name:event.target.value})}}
                 />
                  </Col>
                  <Col xs={9} lg={2}>
                  <Form.Label>Institution Place </Form.Label>
                  <Input type="text" name="place"
                    value={localStudy.place}
                    onChange={(event)=>{setLocalStudy({...localStudy,
                    place:event.target.value})}}
                  >
                </Input>
                    </Col>
                <Col xs={9} lg={2}>
                <Form.Label>Start Year(Ex:1996) </Form.Label>

                  <Input type="number" name="year" 
                  value={localStudy.startYear}
                    onChange={(event)=>{setLocalStudy({...localStudy,
                    startYear:event.target.value})}}
                  />
                 </Col>
                 <Col xs={9} lg={2}>
                 <Form.Label>End Year(Ex:1999) </Form.Label>
                  <Input type="text" name="year" 
                                    value={localStudy.endYear}
                      onChange={(event)=>{setLocalStudy({...localStudy,
                      endYear:event.target.value})}}
                      />
                 </Col>
                 <Col xs={9} lg={2}>
                 <Form.Label>Degree (Initials) </Form.Label>

                  <Input type="text" name="year" 
                                    value={localStudy.degree}
                        onChange={(event)=>{setLocalStudy({...localStudy,
                        degree:event.target.value})}}
                      />
                 </Col>
                 <Col xs={9} lg={1}>
                 <Button title="Add User" icon={faAdd} onClick={(event)=>{handleStudyAdd(event)}} >ADD</Button>
                 </Col>
               </Row>
                  </div>
                 </FormGroup>
       </div>:null}
       <Table hover responsive>
  <thead>
    <tr>
      <th>#</th>
      <th>Institute Name</th>
      <th>Institute Place</th>
      <th>Start Year</th>
      <th>End Year</th>
      <th>Degree</th>
    </tr>
  </thead>
  <tbody>
  {memberInfo.studyDetails && memberInfo.studyDetails.map((item, index)=>
    <tr key={index}>
      <th scope="row">{index+1}</th>
      <td>{item.name}</td>
      <td>{item.place}</td>
      <td>{item.startYear}</td>
      <td>{item.endYear}</td>
      <td>{item.degree}</td>
     {!viewOnly?<td><FontAwesomeIcon icon={faTrash} className="me-2" onClick={(event)=>{handleRemoveStudy(event, item)}}/></td>:null}
    </tr>)}
  </tbody>
</Table>
      <FormGroup>
                 <div className="container text-center">
            <div className="d-flex justify-content-around btn ">
                 <button type="submit" 
             className="text-center bg-primary button-approve is-info" style={{width:"80px", border: 'none', borderRadius:'8px', color:'white'}} 
             onClick={(event)=>{handleAcadamicsNext(event)}}
           >Next</button>
            </div>
            </div>
                </FormGroup>
                  </form>
            </Col>           
          </Row>
      </Card.Body>
    </Card>
    </div>
  )
}
