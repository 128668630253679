import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
//import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import {Alert  } from "reactstrap";
import { Auth } from "aws-amplify";
import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { ProductContext } from "../../ProductProvider";

export default function Signin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showNewPass, setShowNewPass] = useState(false);
  const [newPassword, setNewPassword]=useState('');
  const [user, setUser] = useState("");
  const history=useHistory();
  const { loggedUser, setLoggedUser, alertDetails, setAlertDetails, alertClose } = useContext(ProductContext);

  const handleSignIn = async(event)=>{
    event.preventDefault();
    //console.log("inpts : ", username, password);
    try{
      if(username===""||password===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Username and password cannot be empty"
      }); 
      }else{
    await Auth.signIn(username, password)
    .then((user)=>{
      //console.log("user : ", user);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(user);
        setShowNewPass(true);

      }else{
        var tadmin=false;
        if(user.attributes['profile']==="Admin"){
          tadmin=true;
         }
        setLoggedUser({...loggedUser,
          name:user.attributes['name'],
          email:user.attributes['email'],
          profile:user.attributes['profile'],
          DOB:user.attributes['birthdate'],
          provinceName:user.attributes['family_name'],
          nickname:user.attributes['nickname'],
          //website:user.attributes['website'],
          isAuthenticated:true,
          isAdmin:tadmin
      });
    history.push(Routes.DashboardOverview.path);
    //   navigate("/mydashboard");
  }
})
.catch((error) =>{
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message:"Error Occured - " + error.message
}); 
})
}
}
catch(error){
setAlertDetails({
  ...alertDetails,
  style:"warning",
  open:true,
  message:"Error Occured - " + error.message
}); 
}
}

const handleChangePassword =async(event)=>{
  event.preventDefault();
  try{
    await Auth.completeNewPassword(
      user,        
      newPassword
  ).then(user => {
      // at this time the user is logged in if no MFA required
     // console.log(user);
     var tadmin=false;
     if(user.attributes['profile']==="Admin"){
         tadmin=true;
      }
      setLoggedUser({...loggedUser,
        name:user.attributes['name'],
        email:user.attributes['email'],
        profile:user.attributes['profile'],
        DOB:user.attributes['birthdate'],
        provinceName:user.attributes['family_name'],
        nickname:user.attributes['nickname'],
        isAuthenticated:true,
        isAdmin:tadmin
  });
    history.push(Routes.DashboardOverview.path);
  }).catch(e => {
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message:"Error Occured - " + e.message
    }); 
  });

  }catch(error){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message:"Error Occured - " + error.message
    }); 
  }
}
  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Card.Link as={Link} to={Routes.ResetPassword.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to our platform</h3>
                </div>
                <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
                <Form className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="example@company.com" onChange={(event)=>{setUsername(event.target.value)}}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                  {!showNewPass?  <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder="Password" onChange={(event)=>{setPassword(event.target.value)}}/>
                      </InputGroup>
                    </Form.Group>:
                    <Form.Group id="newpassword" className="mb-4">
                      <Form.Label>New Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder="Password" onChange={(event)=>{setNewPassword(event.target.value)}}/>
                      </InputGroup>
                    </Form.Group>}
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                      </Form.Check>
                      <Card.Link as={Link} to={Routes.ForgotPassword.path} className="small text-end">Lost password?</Card.Link>
                    </div>
                  </Form.Group>
                  {!showNewPass?<Button variant="primary" type="submit" className="w-100" onClick={(event)=>{handleSignIn(event)}}>
                    Sign in
                  </Button>:
                  <Button variant="primary" type="submit" className="w-100" onClick={(event)=>{handleChangePassword(event)}}>
                    Change Password
                  </Button>}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}
