import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';
import { faEye, faClock, faCheck, faCalendar, faCancel, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import {  Table, CardBody, CardTitle, CardSubtitle, CardText, Badge} from 'reactstrap';
import { Button } from '@themesberg/react-bootstrap';
import ViewRequestForm from './ViewRequestForm';
import {ProductContext} from '../../ProductProvider';

import 'balloon-css';

export default function RequestList(props) {
  const { loggedUser } = useContext(ProductContext);
  const {searchResults, handleCancelRequest, handleResolveRequest, handleAttachmentDownload} = props;
  const [collapsed, setCollapsed] = useState(true);
  const [selectedRequest, setSelectedRequest] = useState('');

  const toggle = () => {
    //   console.log("clicked");
         setCollapsed(!collapsed);
     }

  const handleViewRequest =(event, searchresult)=>{
    event.preventDefault();
    setSelectedRequest(searchresult);
    toggle();
  }

  return (
    <div className="m-2">
  <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
            <ModalHeader toggle={toggle}>
              
            </ModalHeader>
            <ModalBody>
                {/*<AddImge setImageFile={setImageFile} profileImage={profileImage} /> */}
                <ViewRequestForm selectedRequest={selectedRequest} handleAttachmentDownload={handleAttachmentDownload}/>
            </ModalBody>
            <ModalFooter>
      {' '}
      <Button onClick={toggle}>
        Close
      </Button>
    </ModalFooter>
            </Modal>

      <Table hover responsive>
        <tbody >
        {searchResults.map((searchresult,index) =>(
        <tr className='card align-items-center' style={{flexDirection:'row', width:'100%', height:'150px', justifyContent:'space-between', border:'2px solid white'}} key={index}>
         {/*} <th scope="row" > <FormGroup check>
        <Input
          id="checkbox2"
          type="checkbox"
        />
         </FormGroup></th>*/}
          <td className='card align-items-center' style={{flexDirection:'row', width:'100%', height:'150px', justifyContent:'space-between', border:'2px solid white'}}>
      <CardBody>
      <CardTitle tag="h5">
     {searchresult.reqSubject}
      </CardTitle>
      <CardSubtitle className="mb-2 text-muted" tag="h6">
      <FontAwesomeIcon icon={faCalendar} className="me-2" /> {searchresult.reqCreateDate}  
      <FontAwesomeIcon icon={faClock} className="me-2" /> {searchresult.reqCreateTime}  
      <Badge className='m-3 ' color={searchresult.reqStatus==="New"?"primary":searchresult.reqStatus==="Closed"?"success":searchresult.reqStatus==="Cancelled"?"warning":"info"}>
      {searchresult.reqStatus}
      </Badge>
      </CardSubtitle>
      <CardText>
        {searchresult.reqComments}
        <br />
        {searchresult.fileAttached && searchresult.attachmentName?<span> <FontAwesomeIcon icon={faPaperclip} className="me-2" onClick={(event)=>{handleAttachmentDownload(event, searchresult)}}/> {searchresult.attachmentName}  </span>:null}
      </CardText>
      </CardBody>
      </td>
      <td className='card align-items-center' style={{flexDirection:'row', height:'150px', justifyContent:'space-between', border:'2px solid white'}}>

    <balloon aria-label="View" data-balloon-pos="up"><FontAwesomeIcon icon={faEye} id="TooltipExample" className="me-2" onClick={(event)=>{handleViewRequest(event, searchresult)}}/></balloon>
    <balloon aria-label="Cancel" data-balloon-pos="up"> <FontAwesomeIcon icon={faCancel} className="me-2" onClick={(event)=>{handleCancelRequest(event, searchresult)}}/></balloon>
   {loggedUser.isAdmin? <balloon aria-label="Resolve" data-balloon-pos="up">  <FontAwesomeIcon icon={faCheck} className="me-2" onClick={(event)=>{handleResolveRequest(event, searchresult)}}/></balloon>:null}
      </td>
      </tr>              
    ))}

       {/*} <tr className='card align-items-center' style={{flexDirection:'row', height:'130px', justifyContent:'space-between', border:'2px solid white'}}>
          <th scope="row" > <FormGroup check>
        <Input
          id="checkbox2"
          type="checkbox"
        />
       
       
      </FormGroup></th>
          <td>
          <CardBody>
      <CardTitle tag="h5">
        Kindly Change my Profile Picture
      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
         <FontAwesomeIcon icon={faClock} className="me-2" /> 10.00 am  
         <Badge className='m-3 ' color="danger">
    In Progress
  </Badge>
      </CardSubtitle>
      <CardText>
        Some quick example text to build on the card title and make up the bulk of the card's content.
      </CardText>
      
    </CardBody>

          </td>
          
        
        <td>
        <FontAwesomeIcon icon={faEye} className="me-2" />
            <FontAwesomeIcon icon={faEdit} className="me-2" />

        </td>
              </tr>*/}
        </tbody>
        </Table>

    </div>
  )
}
