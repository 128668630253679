import React from 'react';
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";

import { Input } from 'reactstrap';
import { FormGroup } from 'react-bootstrap';


export default function ViewRequestForm(props) {
    const {selectedRequest, handleAttachmentDownload}=props;

  return (
  <div>
  <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Request Form</h5>      
        {selectedRequest.reqid}
          <Row className='container p-4'>
            <Col>
                <form > 
                <div className="navbar-bg">
                <FormGroup style={{overflow:"visible"}}>
                <div>
                <Row>
                  <Input
                        type="text"
                        name="subject"
                        value={selectedRequest.reqSubject}
                        disabled
                      />
                  </Row>
                  </div>
                    </FormGroup>
                    <FormGroup style={{overflow:"visible"}}>
                    <div>
                    <Row>
                  <Input type="textarea" name="msg"
                      value={selectedRequest.reqComments}
                      disabled
                      />
                  </Row>
                  </div>
                  {selectedRequest.fileAttached && selectedRequest.attachmentName?<span> <FontAwesomeIcon icon={faPaperclip} className="me-2" onClick={(event)=>{handleAttachmentDownload(event, selectedRequest)}}/> {selectedRequest.attachmentName}  </span>:null}
                    </FormGroup>
                  </div>
                  </form>
            </Col>           
          </Row>
      </Card.Body>
    </Card>
    </div>
  )
}
