import React, {useContext, useState, useEffect} from 'react';
import { Col, Row, Card, Button } from '@themesberg/react-bootstrap';
import { Input, Table , Alert} from 'reactstrap';
import { FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faAdd , faTrash} from "@fortawesome/free-solid-svg-icons";
import {ProductContext} from "../../ProductProvider";
import { nanoid } from 'nanoid';

export default function CommunityProfile(props) {
  const { alertDetails, setAlertDetails, alertClose, memberInfo, setMemberInfo, calculateProgressiveValues } = useContext(ProductContext);
  const [localStudy, setLocalStudy]=useState({id:"1", startYear:"", endYear:"", formationStage:"", formationPlace:"", formationAddress:""});
  const {setTabValue, viewOnly } = props;

  useEffect(()=>{
    alertClose();
    generateNewID();
  },[])

  const generateNewID=()=>{
    const idgen=nanoid(10);
    setLocalStudy({...localStudy,
      id:idgen,startYear:"", endYear:"", formationStage:"", formationPlace:"", formationAddress:""});
  }

  const handleCommunityAdd =async(event)=>{
    event.preventDefault();
    if(localStudy.startYear===""||localStudy.endYear===""||localStudy.formationPlace===""||localStudy.formationAddress===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Fill All the Details to get added!"
    }); 
    window.scrollTo(0,0);
    }else{
      //setStudyDetails([...studyDetails, localStudy]);
      //console.log("Local : ",  localStudy);
      setMemberInfo({...memberInfo,
        communityDetails:[...memberInfo.communityDetails, localStudy]});
        generateNewID();
        calculateProgressiveValues(memberInfo);
    }

  }

  const handleCommunityRemove=(event, member)=>{
    event.preventDefault();
    //console.log("item : ", item);
      let tempCart = [...memberInfo.communityDetails];
      tempCart = tempCart.filter(item => item.id !==member.id);
     // console.log("temp cart :", tempCart);
     setMemberInfo({
      ...memberInfo,
      communityDetails:[...tempCart]
    });
    generateNewID();
    calculateProgressiveValues(memberInfo);
  }

const handleCommunityNext=(event)=>{
  event.preventDefault();
 // setSaveFamilyData(true);
  setTabValue("Home");
}

  return (
    <div>
         <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Community Profile</h5>
        <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
<h6>Add Details</h6>
                  <Row >
            <Col>
                <form > 
                  {!viewOnly?
                <div className="navbar-bg" style={{marginBottom:'20px'}}>
                <FormGroup style={{overflow:"visible"}}>
                <div>
                <Row>
                <Col xs={9} lg={2}>
                  <Input type="number" name="year" placeholder='Start Year(1996)'
                                                    value={localStudy.startYear} 
                    onChange={(event)=>{setLocalStudy({...localStudy,
                    startYear:event.target.value})}}
                  />
                 </Col>
                 <Col xs={9} lg={2}>
                  <Input type="number" name="year" placeholder='End Year(1999)'
                                                    value={localStudy.endYear} 
                      onChange={(event)=>{setLocalStudy({...localStudy,
                      endYear:event.target.value})}}
                      />
                 </Col>
            <Col xs={9} lg={2}>
                  <Input
                        type="text"
                        name="services"
                        placeholder="Service Rendered"
                        value={localStudy.formationStage} 
                        onChange={(event)=>{setLocalStudy({...localStudy,
                          formationStage:event.target.value})}}
                 />
                  </Col>
                                <Col xs={9} lg={3}>
                                <Input type="text" name="place"
                                 placeholder='Community Details (ex Beschi Illam)'
                                 value={localStudy.formationPlace} 
                                 onChange={(event)=>{setLocalStudy({...localStudy,
                                  formationPlace:event.target.value})}}
                                >
                              </Input>
                    </Col>
            
                 <Col xs={9} lg={2}>
                  <Input type="text" name="place" placeholder='Place (ex Dindigul)'
                                                    value={localStudy.formationAddress} 
                        onChange={(event)=>{setLocalStudy({...localStudy,
                        formationAddress:event.target.value})}}
                      />
                 </Col>
                 <Col xs={9} lg={1}>
                 <Button title="Add Community" icon={faAdd} onClick={(event)=>{handleCommunityAdd(event)}} >ADD</Button>
                 </Col>
               </Row>
                  </div>
                 </FormGroup>
       </div>:null}
       <Table hover responsive>
  <thead>
    <tr>
      <th>#</th>
      <th>Start Year</th>
      <th>End Year</th>
      <th>Service Rendered</th>
      <th>Community Details</th>
      <th>Place</th>
    </tr>
  </thead>
  <tbody>
  {memberInfo.communityDetails && memberInfo.communityDetails.map((item, index)=>
    <tr key={index}>
      <th scope="row">{index+1}</th>
      <td>{item.startYear}</td>
      <td>{item.endYear}</td>
      <td>{item.formationStage}</td>
      <td>{item.formationPlace}</td>
      <td>{item.formationAddress}</td>
     {!viewOnly?<td><FontAwesomeIcon icon={faTrash} className="me-2" onClick={(event)=>{handleCommunityRemove(event, item)}}/></td>:null}
    </tr>)}
  </tbody>
</Table>
      <FormGroup>
                 <div className="container text-center">
            <div className="d-flex justify-content-around btn ">
                 <button type="submit" 
             className="text-center bg-primary button-approve is-info" style={{width:"80px", border: 'none', borderRadius:'8px', color:'white'}} 
             onClick={(event)=>{handleCommunityNext(event)}}
           >Next</button>
            </div>
            </div>
                </FormGroup>
                  </form>
            </Col>           
          </Row>
      </Card.Body>
    </Card>
    </div>
  )
}
