import React, {useContext} from 'react'
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import { Label, Input } from 'reactstrap';
import { FormGroup } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import {currentStatusData, gradeData, religiousStatusData } from '../../ProData';

export default function SocSJInfo(props) {
  const { memberInfo, setMemberInfo, calculateProgressiveValues } = useContext(ProductContext);
  const {setTabValue, viewOnly} = props;

  const handleSocietySave=(event)=>{
    event.preventDefault();
    setTabValue("Language");
  }
  const handleGIFocusOut=(event)=>{
    event.preventDefault();
    calculateProgressiveValues(memberInfo);
  }

  return (
    <div>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Society information</h5>
          <Row >
            <Col>
              <form > 
                <div >
                  <FormGroup style={{overflow:"visible"}}>
                  <div >
                  <Row>
                <Col xs={3} lg={2}>
                  <Label for="dateEntrance">Date of Entrance</Label>
                </Col>
                <Col xs={9} lg={4}>
                  <Input type="date" name="dateEntrance"
                  value={memberInfo.entranceDate==="NA"?"":memberInfo.entranceDate}
                  disabled={viewOnly}
                  onBlur={(event)=>{handleGIFocusOut(event)}}
                  onChange={(event)=>{setMemberInfo({...memberInfo, entranceDate:event.target.value})}}
                  />           
                </Col>
                <Col xs={3} lg={2}>
                <Label for="placeentry">Place of Enterance</Label>
                </Col>
                  <Col xs={9} lg={4}>
                  <Input type="text" name="placeentry"
                  value={memberInfo.entrancePlace==="NA"?"":memberInfo.entrancePlace}
                  disabled={viewOnly}
                  onBlur={(event)=>{handleGIFocusOut(event)}}
                  onChange={(event)=>{setMemberInfo({...memberInfo, entrancePlace:event.target.value})}}
                  />           
                </Col>
                </Row>
              </div>
              </FormGroup>
             
              <FormGroup style={{overflow:"visible"}}>
              <div >
              <Row>
              <Col xs={3} lg={2}>
              <Label for="datefirstvows">Date of First Vows</Label>
              </Col>
              <Col xs={9} lg={4}>
              <Input type="date" name="datefirstvows"
              value={memberInfo.firstVowDate==="NA"?"":memberInfo.firstVowDate}
              disabled={viewOnly}
              onBlur={(event)=>{handleGIFocusOut(event)}}
               onChange={(event)=>{setMemberInfo({...memberInfo, firstVowDate:event.target.value})}}
              />          
              </Col>
             <Col xs={3} lg={2}>
            <Label for="pfirstvows">Place of First Vows</Label>
            </Col>
            <Col xs={9} lg={4}>
            <Input type="text" name="pfirstvows"
            value={memberInfo.firstVowPlace==="NA"?"":memberInfo.firstVowPlace}
            disabled={viewOnly}
            onBlur={(event)=>{handleGIFocusOut(event)}}
            onChange={(event)=>{setMemberInfo({...memberInfo, firstVowPlace:event.target.value})}}
            />
            </Col>
            </Row>
            </div>
            </FormGroup>
            <FormGroup style={{overflow:"visible"}}>
            <div >
            <Row>
            <Col xs={3} lg={2}>
            <Label for="dateOrdainL">Date of Ordination</Label>
            </Col>
            <Col xs={9} lg={4}>
            <Input type="date" name="dateOrdain"
            value={memberInfo.ordinationDate==="NA"?"":memberInfo.ordinationDate}
            disabled={viewOnly}
            onChange={(event)=>{setMemberInfo({...memberInfo, ordinationDate:event.target.value})}}
           />
            </Col>
                       <Col xs={3} lg={2}>
  <Label for="pOrdination">Place of Ordination</Label>
  </Col>
                  <Col xs={9} lg={4}>
  <Input type="text" name="pOrdination"
        value={memberInfo.ordinationPlace==="NA"?"":memberInfo.ordinationPlace}
        disabled={viewOnly}
        onChange={(event)=>{setMemberInfo({...memberInfo, ordinationPlace:event.target.value})}}
      />
  </Col>
  </Row>
  </div>
    </FormGroup>
    <FormGroup style={{overflow:"visible"}}>
    <div >
    <Row>
  <Col xs={3} lg={2}>
<Label for="bishopOrdained">Ordaining Bishop</Label>
</Col>
  <Col xs={9} lg={4}>
  <Input type="text" name="bishopOrdained"
      value={memberInfo.ordainingBishop==="NA"?"":memberInfo.ordainingBishop}
      disabled={viewOnly}
    onChange={(event)=>{setMemberInfo({...memberInfo, ordainingBishop:event.target.value})}}
      />
</Col>
<Col xs={3} lg={2}>
  <Label for="tertianshipPlace">Place of Tertianship</Label>
  </Col>
  <Col xs={9} lg={4}>
  <Input type="text" name="tertianshipPlace"
        value={memberInfo.tertainshipPlace==="NA"?"":memberInfo.tertainshipPlace}
        disabled={viewOnly}
        onChange={(event)=>{setMemberInfo({...memberInfo, tertainshipPlace:event.target.value})}}
      />
  </Col>
                  </Row>
  </div>
    </FormGroup>
    <FormGroup style={{overflow:"visible"}}>
    <div >
    <Row>
    <Col xs={3} lg={2}>
<Label for="endDateTertianship">End Date of Tertianship</Label>
</Col>
  <Col xs={9} lg={4}>
  <Input type="date" name="endDateTertianship"
          value={memberInfo.tertainshipEndDate==="NA"?"":memberInfo.tertainshipEndDate}
          disabled={viewOnly}
          onChange={(event)=>{setMemberInfo({...memberInfo, tertainshipEndDate:event.target.value})}}
              />
</Col>
                       <Col xs={3} lg={2}>
  <Label for="TertianInstructor">Name of Tertian Instructor</Label>
  </Col>
                  <Col xs={9} lg={4}>
  <Input type="text" name="TertianInstructor"
        value={memberInfo.tertainInstructor==="NA"?"":memberInfo.tertainInstructor}
        disabled={viewOnly}
        onChange={(event)=>{setMemberInfo({...memberInfo, tertainInstructor:event.target.value})}}
      />
  </Col>
  </Row>
  </div>
    </FormGroup>
    <FormGroup style={{overflow:"visible"}}>
    <div >
    <Row>
   <Col xs={3} lg={2}>
<Label for="datefinalvows">Date of Final Vows</Label>
</Col>
  <Col xs={9} lg={4}>
  <Input type="date" name="datefinalvows"
      value={memberInfo.finalVowsDate==="NA"?"":memberInfo.finalVowsDate}
      disabled={viewOnly}
      onChange={(event)=>{setMemberInfo({...memberInfo, finalVowsDate:event.target.value})}}
      />
</Col>
                       <Col xs={3} lg={2}>
  <Label for="placefinalvows">Place of Final Vows</Label>
  </Col>
                  <Col xs={9} lg={4}>
  <Input type="text" name="placefinalvows"
        value={memberInfo.finalVowsPlace==="NA"?"":memberInfo.finalVowsPlace}
        disabled={viewOnly}
        onChange={(event)=>{setMemberInfo({...memberInfo, finalVowsPlace:event.target.value})}}
      />
  </Col>
                  </Row>
  </div>
    </FormGroup>
    <FormGroup style={{overflow:"visible"}}>
    <div>
    <Row>
      <Col xs={3} lg={2}>
<Label for="currentStatusL">Current Status</Label>
</Col>
     <Col xs={9} lg={4}>
      <Input type="select" name="currentStatus"
          value={memberInfo.currentStatus==="NA"?"":memberInfo.currentStatus}
          disabled={viewOnly}
          onChange={(event)=>{setMemberInfo({...memberInfo, currentStatus:event.target.value})}} 
              >
              {currentStatusData.map(item => {
          return (<option key={item.name} value={item.name}>{item.name}</option>);
            })}
     </Input>     
</Col>
                       <Col xs={3} lg={2}>
  <Label for="religiousStatusL">Religious Status</Label>
  </Col>
                  <Col xs={9} lg={4}>
      <Input type="select" name="religiousStatus"
        value={memberInfo.religiousStatus==="NA"?"":memberInfo.religiousStatus}
        disabled={viewOnly}
          onChange={(event)=>{setMemberInfo({...memberInfo, religiousStatus:event.target.value})}}              
      >
        {religiousStatusData.map(item => {
              return (<option key={item.name} value={item.name}>{item.name}</option>);
            })}        
              </Input>     
  </Col>
                  </Row>
  </div>
    </FormGroup>
    <FormGroup style={{overflow:"visible"}}>
    <div>
    <Row>
                <Col xs={3} lg={2}>
<Label for="gradeL">Grade</Label>
</Col>
                  <Col xs={9} lg={4}>
      <Input type="select" name="grade"
          value={memberInfo.grade==="NA"?"":memberInfo.grade}
          disabled={viewOnly}
          onChange={(event)=>{setMemberInfo({...memberInfo, grade:event.target.value})}} 
              >
                 {gradeData.map(item => {
              return (<option key={item.name} value={item.name}>{item.name}</option>);
            })}
              </Input>     
</Col>
                       {/*<Col xs={3} lg={2}>
  <Label for="religiousStatusL">Religious Status / Grade</Label>
  </Col>
                  <Col xs={9} lg={4}>
  
      <Input type="select" name="religiousStatus"
          onChange={(event)=>{setMemberInfo({...memberInfo, religiousStatus:event.target.value})}}              
      >
        {religiousStatusData.map(item => {
              return (<option key={item.name} value={item.name}>{item.name}</option>);
            })}        
               
              </Input>     
          </Col>*/}
                  </Row>
  </div>
  </FormGroup>
    <FormGroup style={{overflow:"visible"}}>
  <div>
  <Row>
  <Col xs={3} lg={2}>
  <Label for="proventered">Province Entered</Label>
  </Col>
    <Col xs={9} lg={4}>
    <Input type="text" name="proventered"
          value={memberInfo.provinceEntered==="NA"?"":memberInfo.provinceEntered}
          disabled={viewOnly}
            onChange={(event)=>{setMemberInfo({...memberInfo, provinceEntered:event.target.value})}}
              >
              </Input>     
       </Col>
       {memberInfo.currentStatus==="Left" &&(<div> <Col xs={3} lg={2}>
  <Label for="dateLeft">Date Left</Label>
  </Col>
    <Col xs={9} lg={4}>
    <Input
          type="date"
          name="dateLeft"
          value={memberInfo.dateLeft==="NA"?"":memberInfo.dateLeft}
          disabled={viewOnly}
          onChange={(event)=>{setMemberInfo({...memberInfo, dateLeft:event.target.value})}}
         />
 </Col></div>)}
    </Row>
    </div>
      </FormGroup>  
      {memberInfo.currentStatus==="Transcribed" &&( <FormGroup style={{overflow:"visible"}}>
  <div>
  <Row>
  <Col xs={3} lg={2}>
  <Label for="dateTransferred">Date Transferred</Label>
  </Col>
    <Col xs={9} lg={4}>
    <Input
          type="date"
          name="dateTransferred"
          value={memberInfo.dateTransferred==="NA"?"":memberInfo.dateTransferred}
          disabled={viewOnly}
          onChange={(event)=>{setMemberInfo({...memberInfo, dateTransferred:event.target.value})}}       
        />
  </Col>
         <Col xs={3} lg={2}>
    <Label for="provTransferred">Province Transfered</Label>
    </Col>
    <Col xs={9} lg={4}>
    <Input
          type="text"
          name="provTransferred"
          value={memberInfo.transferredProvicialiateName==="NA"?"":memberInfo.transferredProvicialiateName}
          disabled={viewOnly}
          onChange={(event)=>{setMemberInfo({...memberInfo, transferredProvicialiateName:event.target.value})}}
        />
    </Col>
    </Row>
    </div>
      </FormGroup>)}
    {memberInfo.currentStatus==="Died" &&( <FormGroup style={{overflow:"visible"}}>
  <div>
  <Row>
  <Col xs={3} lg={2}>
  <Label for="dateOfDeath">Date of Death</Label>
  </Col>
    <Col xs={9} lg={4}>
    <Input
          type="date"
          name="dateOfDeath"
          value={memberInfo.deathDate==="NA"?"":memberInfo.deathDate}
          disabled={viewOnly}
          onChange={(event)=>{setMemberInfo({...memberInfo, deathDate:event.target.value})}}
         />
  </Col>
         <Col xs={3} lg={2}>
    <Label for="placeOfDeath">Place of Death</Label>
    </Col>
    <Col xs={9} lg={4}>
    <Input
          type="text"
          name="placeOfDeath"
          value={memberInfo.deathPlace==="NA"?"":memberInfo.deathPlace}
          disabled={viewOnly}
          onChange={(event)=>{setMemberInfo({...memberInfo, deathPlace:event.target.value})}}
          />
    </Col>
    </Row>
    </div>      </FormGroup>)}
    {memberInfo.currentStatus==="Died" &&( <FormGroup style={{overflow:"visible"}}>
  <div>
  <Row>
  <Col xs={3} lg={2}>
  <Label for="placeOfBurial"> Place Burried</Label>
  </Col>
    <Col xs={9} lg={4}>
    <Input
          type="text"
          name="placeOfBurial"
          value={memberInfo.burialPlace==="NA"?"":memberInfo.burialPlace}
          disabled={viewOnly}
          onChange={(event)=>{setMemberInfo({...memberInfo, burialPlace:event.target.value})}}
        />
  </Col>
  {/*<Col xs={3} lg={2}>
<Label for="scope">Details</Label>
</Col>
                  <Col xs={9} lg={4}>
  <Input type="textarea" name="scope"
                                  value={memberGI.otherName}

          onChange={(event)=>{setMemberInfo({...memberInfo, details:event.target.value})}}
              
      />
            
        </Col> */}
        {/*} <Col xs={3} lg={2}>
    <Label for="placeOfDeath">Place of Death</Label>
    </Col>
    <Col xs={9} lg={4}>
    <Input
          type="date"
          name="placeOfDeath"
          value={user.placeOfDeath}
          onChange={(event)=>{setUser({...user,placeOfDeath:(event.target.value)})}}  
        />
          </Col>*/}
    </Row>
    </div>
      </FormGroup>)}
       </div>
      <FormGroup>
                 <div className="container text-center">
            <div className="d-flex justify-content-around btn ">
                  <button type="submit" onClick={(event)=>{handleSocietySave(event)}}
               className="text-center bg-primary button-approve is-info" style={{width:"80px", border: 'none', borderRadius:'8px', color:'white'}}
             >Next</button>
            </div>
            </div>
                </FormGroup>
                  </form>
            </Col>           
          </Row>
      </Card.Body>
    </Card>
    </div>
  )
}
