import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";

import {Breadcrumb, BreadcrumbItem, Alert } from "reactstrap";
import {ProductContext} from '../../ProductProvider';
import MemberForm from "../members/MemberForm";
import Preloader from "../../components/Preloader";
import { Routes } from "../../routes";

const config = require('../../config.json');


export default function MyProfileView(){
  const { loggedUser, myProfileImage, assignValues, resetValues, alertDetails, setAlertDetails, alertClose, validateLogin } = useContext(ProductContext);
  const [profileImage, setProfileImage] = useState('defaultAvatar.jpg');
  const [loaded, setLoaded] = useState(false);
  //const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      fetchMyDetails();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      resetValues();
      alertClose();
    }
   // console.log(batchYear);
  },[loggedUser])


  const fetchMyDetails = async() =>{
    //showLoader();
    try{
      setLoaded(false);
      const searchOption="UserID";
      //const tcommunity="SJMDU";
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      //console.log("nick name : ", loggedUser.nickname);
      //console.log("province : ", loggedUser.provinceName);
      const res = await axios.get(`${config.api.invokeUrl}/global_search?searchValue=${loggedUser.nickname}&searchOption=${searchOption}&familyName=${loggedUser.provinceName}`,{
        headers:{
            Authorization: access_token,
            'x-api-key': config.api.key
        }});
      //console.log("Res : ", res);
      if(res.data.length>0){
       assignValues(res.data[0]);
       // console.log(temp);
       setProfileImage(`${config.s3.imageURL}${res.data[0].profileImage}`);

     //  setProfileImage(res.data[0].profileImage);
      // calculateProgressiveValues(res.data[0]); 
       /* const tprofiledata=res.data[0];
        var countEmpty=0;
        var countNotEmpty=0;
        for (var key in tprofiledata) {
        //  if(key==="tertainInstructor"||key!="provinceEntered"||key!="provTransferred"||key!="dateLeft"||key!="otherName"||key!="dateOfDeath"||key!="tertainshipPlace"||key!="placeOfBurial"||key!="dateTransferred"||key!="tertainshipEndDate"||key!="placeOfDeath"){
        if(key==="Grade"||key==="studyDetails"||key==="emailId"||key==="phoneNo"||key==="firstVowPlace"||key==="lastVow"||key==="userId"||key==="communityName"||key==="religousStatus"||key==="diocese"||key==="dobActual"||key==="firstVowDate"||key==="ordainingBishop"||key==="familyDetails"||key==="nationality"||key==="placeOfBirth"||key==="currentStatus"||key==="dateJoined"||key==="dobOfficial"||key==="dateOrdain"||key==="finalVowsPlace"||key==="entrancePlace"||key==="missionName"||key==="username"||key==="bloodGroup") {   
        //console.log("Key : ", key);

            if (tprofiledata[key] !=="")
              countNotEmpty++;
              else
              countEmpty++;
          }
      }
      const tprogressbarValue=countNotEmpty/(countEmpty+countNotEmpty);
      setProgressValue(tprogressbarValue*100);*/
      setLoaded(true);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: "My Profile loaded Successfully!"
    });  
   

       /* const {communityName, missionName, userId, username,currentStatus, dateOfBirth, placeOfBirth,nationality, phoneNo, emailId, dateJoined,dateOrdain,lastVow, Grade,religousStatus,details,dateLeft, dateTransferred,provTransferred, dateOfDeath, placeOfDeath, placeOfBurial} = res.data[0];
       // processProgressBarUpdate(res.data[0]);
        //console.log("pro : ", pro);

//console.log(res1);

        const tImage=userId+".jpeg";
        setProfileImage(tImage);
        setUser({
            ...user,
            communityName: communityName,
            missionName:missionName,
          userId: userId,
          username: username,
          currentStatus: currentStatus,
          dateOfBirth: dateOfBirth,
          placeOfBirth:placeOfBirth,
          phoneNo: phoneNo,    
          emailId: emailId,
          dateJoined: dateJoined,
          dateOrdain:dateOrdain,
          lastVow: lastVow,
          Grade: Grade,
          religousStatus: religousStatus,
          details: details,
          nationality:nationality,
          dateLeft:dateLeft, 
          dateTransferred:dateTransferred,
          provTransferred:provTransferred, 
          dateOfDeath:dateOfDeath, 
          placeOfDeath:placeOfDeath, 
          placeOfBurial:placeOfBurial
        })
      }else{
      //  setDispMsg("Error fetching your details. Try Again");
      }
    //console.log("Response : ", res);*/
    }
    setLoaded(true);

    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);
    window.scrollTo(0,0);   
   
      //setDispMsg("Error Occured - ", error.message);
     // hideLoader();
    }
    //hideLoader();
  }

  return (
      <div>
         <Preloader show={loaded ? false : true} />
         <Breadcrumb>
    <BreadcrumbItem>
      <Link to={Routes.LandingPage.path}>
        Home
      </Link>
    </BreadcrumbItem>
    <BreadcrumbItem >
      My Profile
    </BreadcrumbItem>
  </Breadcrumb>
  <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>

        {/* profile View*/}
        <MemberForm formType="My Profile" profileImage={myProfileImage} setProfileImage={setProfileImage} viewOnly={true}/>
       {/*} <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
    
    <Tab eventKey="home" title="General Information">
   <GenInfor />
  </Tab>
  <Tab eventKey="family" title="Family Information">
   <FamInfo />
  </Tab>
  <Tab eventKey="profile" title="Society Information">
    <SocSJInfo />
  </Tab>
  <Tab eventKey="studies" title="Studies" >
    <StudiesInfo />
  </Tab>

  <Tab eventKey="other" title="Other" >
    
  </Tab>

  </Tabs>*/}
          
          
      </div>
   
  );
};
