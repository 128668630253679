import React, { useEffect, useState, useContext } from "react";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCashRegister, faChartLine, faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from '@themesberg/react-bootstrap';
import { Auth } from "aws-amplify";
import axios from "axios";
import Preloader from "../../components/Preloader";
import { Routes } from "../../routes";
import {Breadcrumb, BreadcrumbItem, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { ProductContext } from '../../ProductProvider';
import { CircleChartWidget, BarChartWidget, TeamMembersWidget, ProgressTrackWidget } from "../../components/Widgets";
//import { PageVisitsTable } from "../../components/Tables";
//import { trafficShares, totalOrders } from "../../data/charts";

const config = require('../../config.json');

export default () => {
  const [dashboardData, setDashboardData]=useState([]);
  const [loaded, setLoaded] = useState(false);
  const { alertClose, alertDetails, setAlertDetails, loggedUser, validateLogin } = useContext(ProductContext);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      fetchDashboardValues();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }
   // console.log(batchYear);
  },[loggedUser])

  const fetchDashboardValues=async()=>{
    try {
          //console.log("search Value : ", searchValue);
          //console.log("search Option : ", searchOption);
        //showLoader();
        setLoaded(false);
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          const familyname="SJMDU";
          //const searchOption="User ID";
          const res = await axios.get(`${config.api.invokeUrl}/dashboard?familyName=${familyname}`,{
              headers:{
                  Authorization: access_token,
                  'x-api-key': config.api.key
              }
          });
         // console.log("res DAta1 : ", res);
         /* setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: res.data.length + " - Users found"
          }); 
          window.scrollTo(0,0); 
         /* var i=0;
          while(i<res.data.length){
          //  console.log("before value : ", res.data[i].Book_Classification_No);
           const tempClassValue= [...bookClassification].filter(searchresult =>searchresult.value===res.data[i].Book_Classification_No);
           res.data[i].Book_Classification_No=tempClassValue[0].name;
           //console.log("temp class vale : ", tempClassValue);
           //console.log("After update : ", res.data[i].Book_Classification_No);

           i++;
          }*/
          setDashboardData(res.data[0]);
          //hideLoader();
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: "Dashboard loaded Successfully!"
        });  
          setLoaded(true);

  } catch (error) {
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message:"Error Occured - " + error.message
  }); 
  setLoaded(true);
  }
  }

  return (
    <>
             <Preloader show={loaded ? false : true} />
             <Breadcrumb>
    <BreadcrumbItem>
      <Link to={Routes.LandingPage.path}>
        Home
      </Link>
    </BreadcrumbItem>
    <BreadcrumbItem >
      Dashboard
    </BreadcrumbItem>
  </Breadcrumb>
  <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>

   {/*   <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Dropdown className="btn-toolbar">
          <Dropdown.Toggle as={Button} variant="primary" size="sm" className="me-2">
            <FontAwesomeIcon icon={faPlus} className="me-2" />New Task
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faTasks} className="me-2" /> New Task
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" /> Upload Files
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faUserShield} className="me-2" /> Preview Security
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faRocket} className="text-danger me-2" /> Upgrade to Pro
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <ButtonGroup>
          <Button variant="outline-primary" size="sm">Share</Button>
          <Button variant="outline-primary" size="sm">Export</Button>
        </ButtonGroup>
      </div>
  */}
      <Row className="justify-content-md-center">
      {/*}  <Col xs={12} className="mb-4 d-none d-sm-block">
          <SalesValueWidget
            title="Sales Value"
            value="10,567"
            percentage={10.57}
          />
        </Col>
        <Col xs={12} className="mb-4 d-sm-none">
          <SalesValueWidgetPhone
            title="Sales Value"
            value="10,567"
            percentage={10.57}
          />
</Col>*/}
       {/*} <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Customers"
            title="345k"
            period="Feb 1 - Apr 1"
            percentage={18.2}
            icon={faChartLine}
            iconColor="shape-secondary"
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Revenue"
            title="$43,594"
            period="Feb 1 - Apr 1"
            percentage={28.4}
            icon={faCashRegister}
            iconColor="shape-tertiary"
          />
</Col>*/}
        {dashboardData?<Col xs={12} sm={6} xl={4} className="mb-2">
          <CircleChartWidget
            title="Religious Status"
            data={dashboardData.religousStatus} />
        </Col>:null}
        {dashboardData?<Col xs={12} sm={6} xl={4} className="mb-2">
          <CircleChartWidget
            title="Request Info"
            data={dashboardData.requestStatus} />
        </Col>:null}
      </Row>
      <Row className="justify-content-md-center">
      {dashboardData?<Col xs={12} sm={6} xl={4} className="mb-2">
          <CircleChartWidget
            title="Current Status"
            data={dashboardData.currentStatus} />
        </Col>:null}
        {dashboardData?<Col xs={12} sm={6} xl={4} className="mb-2">
          <CircleChartWidget
            title="Grade Details"
            data={dashboardData.grade} />
        </Col>:null}
      </Row>

   {/*}   <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Row>
            <Col xs={12} xl={8} className="mb-4">
              <Row>
              {/*}  <Col xs={12} className="mb-4">
                  <PageVisitsTable />
</Col>

                <Col xs={12} lg={6} className="mb-4">
                  <TeamMembersWidget />
</Col>

                <Col xs={12} lg={6} className="mb-4">
                  <ProgressTrackWidget />
                </Col>
              </Row>
            </Col>

            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12} className="mb-4">
                  <BarChartWidget
                    title="Total orders"
                    value={452}
                    percentage={18.2}
                    data={totalOrders} />
                </Col>

               {/*} <Col xs={12} className="px-0 mb-4">
                  <RankingWidget />
                </Col>

                <Col xs={12} className="px-0">
                  <AcquisitionWidget />
</Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>*/}
    </>
  );
};
