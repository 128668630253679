
import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen, faLock, faRemove, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import axios from "axios";

//import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/img/team/profile-picture-2.jpg";
import { ProductContext } from "../ProductProvider";
import {Routes} from '../routes';
const config = require('../config.json');


export default function Navabar(props) {
  //const [notifications, setNotifications] = useState([]);
  //const [areNotificationsRead, setAreNotificationsRead]=useState(true);
  //const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.reqStatus, "New");
  const { loggedUser, setLoggedUser, myProfileImage, notifications, setNotifications, areNotificationsRead, setAreNotificationsRead,handleImageError } = useContext(ProductContext);
  const history=useHistory();

 /* useEffect(()=>{
  /*  if(loggedUser.isAdmin){
      const timer = setTimeout(() => fetchOpenItems(), 1000);
    clearTimeout(timer);

      
    }
    //console.log("Image : ", myProfileImage);
    //console.log("test : ", notifications.reduce((acc, notif) => acc && notif.read, true));
    //setAreNotificationsRead(notifications.reduce((acc, notif) => acc && notif.reqStatus, "New"));
  },[])

  const fetchOpenItems=async()=>{
    try {
     // console.log("testing");
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      const familyname="SJMDU";
      const searchOption="ReqStatus";
      const searchValue="New";
      const res = await axios.get(`${config.api.invokeUrl}/user_request?searchValue=${searchValue}&searchOption=${searchOption}&familyName=${familyname}`,{
          headers:{
              Authorization: access_token,
              'x-api-key': config.api.key
          }
      });
      if(res.data.length>0){
      setNotifications(res.data);  
      setAreNotificationsRead(false);
      }
      //console.log(res.data);
} catch (error) {
}

  }*/

  const handleSignOut=async()=>{
    try{
      await Auth.signOut()
      .then((res)=>{
        setLoggedUser({...loggedUser,
          name:'',
          email:'',
          DOB:'',
          profile:'',
          provinceName:'',
          nickname:'',
          website:'',
          isAuthenticated:false
        }); 
        history.push(Routes.Signin.path);

      }).catch((error)=>{
        console.log("Error ", error.message);
      })
    }catch(error){
      console.log("Error ", error.message);
    }
  }

  const handleProfileNav =()=>{
    history.push(Routes.MyProfileView.path);
  }

  const handleNotificationNav =()=>{
    history.push(Routes.OpenList.path);
  }

  const handleChangePwdNav =async()=>{
    history.push(Routes.ResetPassword.path);
  }

  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map(n => ({ ...n, read: true })));
    }, 300);
  };


  const Notification = (props) => {
    const { link, reqSubject, reqCreateDate, reqComments, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{reqSubject}</h4>
              </div>
              <div className="text-end">
                <small className={reqCreateDate}>{}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{reqComments}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <h2>SJ MDU - Online Catalogue</h2>
          {/*}  <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                  <Form.Control type="text" placeholder="Search User" />
                </InputGroup>
              </Form.Group>
  </Form>*/}
          </div>
          <Nav className="align-items-center">
           {loggedUser.isAdmin? <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >
              <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                    {notifications.length} - Notifications
                  </Nav.Link>
                  <Dropdown.Toggle  as={Nav.Link} className="text-dark icon-notifications me-lg-3">
               <div onClick={handleNotificationNav}>
                 {notifications.map(n => <Notification  key={`notification-${n.reqNo}`} {...n} />)}
                 </div>   

                  <Dropdown.Item onClick={handleNotificationNav} className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                  </Dropdown.Toggle>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>:null}

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={myProfileImage} className="user-avatar md-avatar rounded-circle" onError={handleImageError}/>
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{loggedUser.name}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item onClick={handleProfileNav} className="fw-bold">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
                </Dropdown.Item>
            
                <Dropdown.Item onClick={handleChangePwdNav} className="fw-bold">
                  <FontAwesomeIcon icon={faLock} className="me-2" /> Change Password
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item className="fw-bold" onClick={handleSignOut}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
