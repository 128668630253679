
export const Routes = {
    // pages
    LandingPage: { path: "/dashboard/overview" },
    MyProfileView:{path:"/dashboard/myprofile"},
    UserSearch:{path:"/dashboard/search-member"},
    AddMember:{path:"/dashboard/add-member"},
    UserRequest:{path:"/dashboard/new-request"},
    TaskList:{path:"/dashboard/my-request"},
    OpenList:{path:"/dashboard/open-request"},
    Presentation: { path: "/" },
    DashboardOverview: { path: "/dashboard/overview" },
    LoginAccount:{path:"/dashboard/login-account"},

    //AddUser: {path:"/addusers"},
    Signin: { path: "/dashboard/sign-in" },
    ForgotPassword: { path: "/dashboard/forgot-password" },
    ResetPassword: { path: "/dashboard/reset-password" },
    NotFound: { path: "/dashboard/404" },
    ServerError: { path: "/dashboard/500" },
    
    //Images
    ImageViewer: { path: "/imageviewer" },
};