import React,{ useState, useContext, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Alert  } from "reactstrap";
import {Breadcrumb, BreadcrumbItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Preloader from "../../components/Preloader";
import { Routes } from "../../routes";
import { ProductContext } from "../../ProductProvider";

export default function ResetPassword() {
  const history=useHistory();
  const { loggedUser, alertClose, alertDetails, setAlertDetails, validateLogin } = useContext(ProductContext)
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loaded, setLoaded] = useState(true);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }
   // console.log(batchYear);
  },[loggedUser])

  const handleChangePassword = async(event) =>{
    event.preventDefault();
    try{
        if(oldPassword==="" || newPassword===""){
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Enter the password!"
          }); 
        }else{
          setLoaded(false);

        await Auth.currentAuthenticatedUser()
        .then(user => {
            return Auth.changePassword(user, oldPassword, newPassword);
        })
        .then(data => {
            if(data==="SUCCESS"){
                setOldPassword('');
                setNewPassword('');
                setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message:"Password Changed Successfully!"
              }); 
                
            }              
            setLoaded(true);

          })
        .catch(err => {
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + err.message
        }); 
        setLoaded(true);

        });
        }
    }catch(error){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      setLoaded(true);

   }
}
  return (
    <main>
             <Preloader show={loaded ? false : true} />
         <Breadcrumb>
    <BreadcrumbItem>
      <Link to={Routes.LandingPage.path}>
        Home
      </Link>
    </BreadcrumbItem>
    <BreadcrumbItem >
      Change Password
    </BreadcrumbItem>
  </Breadcrumb>

      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            {/*<p className="text-center">
              <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to sign in
              </Card.Link>
  </p>*/}
   <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
             {alertDetails.message}
            </Alert>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Change password</h3>
                <Form>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" value={loggedUser.email} disabled/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Current Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" value={(event)=>{setOldPassword(event.target.value)}}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" value={(event)=>{setNewPassword(event.target.value)}}/>
                    </InputGroup>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100" onClick={(event)=>{handleChangePassword(event)}}>
                    Change password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
