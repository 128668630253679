import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";
//import { Auth } from "aws-amplify";
import MyProfileView from "./profile/MyProfileView";
import AddMember from './members/AddMember';
import SearchMember from './members/SearchMember';
import NewRequestForm from './request/NewRequestForm';
import MyRequestList from './request/MyRequestList';
import DashboardOverview from "./dashboard/DashboardOverview";
import Signin from "./auth/Signin";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import NotFoundPage from "./auth/NotFound";
import ServerError from "./auth/ServerError";
// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Preloader from "../components/Preloader";

//import { ProductContext } from '../ProductProvider';
import OpenRequestList from './request/OpenRequestList';
import LoginAccount from './members/LoginAccount';
//const config = require('../config.json');

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
      const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);


  /*const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }*/

  //const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

 /* const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }*/

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
         {/*} <Footer toggleSettings={toggleSettings} showSettings={showSettings} />*/}
        </main>
      </>
    )}
    />
  );
};

export default function HomePage() {
  //const history= useHistory();
 // const { loggedUser, validateLogin} = useContext(ProductContext);

  /*useEffect(() => {
    if(!loggedUser.isAuthenticated){
      validateLogin();   
    }
   },[loggedUser]);*/
 
 /*  const validateLogin = async() =>{
     try{
        await Auth.currentAuthenticatedUser()
       .then(async(user)=>{
         //console.log("user : ", user);
         var tadmin=false;
         if(user.attributes['profile']==="Admin"){
          tadmin=true;
         }
         if(tadmin){
          await fetchOpenItems();
         }
         await setLoggedUser({...loggedUser,
           name:user.attributes['name'],
           email:user.attributes['email'],
           profile:user.attributes['profile'],
           DOB:user.attributes['birthdate'],
           provinceName:user.attributes['family_name'],
           nickname:user.attributes['nickname'],
           //website:user.attributes['website'],
           isAuthenticated:true,
           isAdmin:tadmin
         });
         const tImageName=user.attributes['nickname']+".jpeg";
         setMyProfileImage(`${config.s3.imageURL}${tImageName}`);
        // navigate("/");   
        history.push(Routes.DashboardOverview.path);
         
       })
       .catch((error) =>{
       //  console.log("error:", error);
         setLoggedUser({...loggedUser,
           name:'',
           email:'',
          // currentStatus:'',
           DOB:'',
           profile:'',
           //mobile:'',
           provinceName:'',
           isAuthenticated:false,
           isAdmin:false
         }); 
         history.push(Routes.Signin.path);
 
       })     
     }catch(error){
      // console.log(error);
     }
   }*/
 return(
  <Switch>
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.ResetPassword.path} component={ResetPassword} />

    <RouteWithSidebar exact path={Routes.Presentation.path} component={DashboardOverview} />
    <RouteWithSidebar exact path={Routes.MyProfileView.path} component={MyProfileView} />
    <RouteWithSidebar exact path={Routes.UserSearch.path} component={SearchMember} />
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
    <RouteWithSidebar exact path={Routes.AddMember.path} component={AddMember} />
    <RouteWithSidebar exact path={Routes.LoginAccount.path} component={LoginAccount} />

    <RouteWithSidebar exact path={Routes.UserRequest.path} component={NewRequestForm} />
    <RouteWithSidebar exact path={Routes.TaskList.path} component={MyRequestList} />
    <RouteWithSidebar exact path={Routes.OpenList.path} component={OpenRequestList} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
)
 }
