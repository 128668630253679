import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEllipsisH, faPlusCircle, faExchange } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, Table, Dropdown } from '@themesberg/react-bootstrap';
import axios from "axios";
import { Auth } from "aws-amplify";
import { ProductContext } from '../../ProductProvider';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import Preloader from "../../components/Preloader";
import EmailUpdateModal from "./EmailUpdateModal";
import {Helmet} from 'react-helmet-async';
const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const phoneRegex=/^\+[1-9]{1}[0-9]{3,14}$/;
const dobRegex=/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

export default () => {
  const { alertClose, alertDetails, setAlertDetails, loggedUser, validateLogin } = useContext(ProductContext);
  const [batchYear, setBatchYear] = useState([]);
  const [selectedBatch, setSelectedBatch]=useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loaded, setLoaded]=useState(true);
  const componentRef = React.useRef(null);
  const [updateEmailCollapsed, setUpdateEmailCollapsed]=useState(true);
  const [selectedEmail, setSelectedEmail]=useState('');
  const [selectedRegistrationNo, setSelectedRegistrationNo]=useState('');
  const [registeredResults, setRegisteredResults]=useState([]);
  const [missingResults, setMissingResults]=useState([]);
  
  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      processBatchYear();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }
   // console.log(batchYear);
  },[loggedUser])

  const processBatchYear=()=>{
    const current_datetime = new Date();
    const date_year = (current_datetime.getFullYear()).toString();
    var tbatchYear=[];
    for(var i=0; i<10;i++){
      tbatchYear.push(date_year-i);
    }
    setBatchYear(tbatchYear);
    setSelectedBatch(date_year);
  }

  const fetchCurrentYearDetails=async()=>{
    try{
      if(selectedBatch===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Search Value is empty Enter the search input and then hit Search button!"
      });  
      }else{
        setLoaded(false);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken; 
        await axios.get(`${config.api.invokeUrl}/accounts/userslist?batchvalue=${selectedBatch}`, {  
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
        if(response.data.length>0)
        {
            setSearchResults(response.data[0]);
            setRegisteredResults(response.data[1]);
            processResults(response.data[0], response.data[1]);
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: "Batch " + selectedBatch +  " loaded Successfully!"
          });  

        }
        setLoaded(true);

    }).catch((error)=>{
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
  })
}
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }
  }

  const processResults=(taccounts,tregister)=>{
   // console.log("Register : ", tregister);
   // console.log("Users : ", taccounts);
    var ttaccounts=[];
    var tttaccounts=[];
    for (const i in taccounts){
      var tobject={};
      tobject.regNo=taccounts[i].Attributes[0]['Value'];
      ttaccounts.push(taccounts[i].Attributes[0]['Value']);
      tttaccounts.push(tobject);
    }
   // console.log("TTaccounts : ", tttaccounts);
   /* var absent = tregister.filter(object1 => {
      return !taccounts.some(object2 => {
        console.log("Reg : ", object2.Attributes[0]['Value']);
        return object1.registrationNo === object2.Attributes[0]['Value'];
      });
    });*/
    //var objectmissing = tregister.filter((item)=>{taccounts.includes(account=>{ item.registrationNo!=account.Attributes[0]['Value']})});
    //var absent1 = tregister.filter((item)=>{tttaccounts.includes(account=>{ item.registrationNo!=account})});

    const results = tregister.filter(({ registrationNo: id1 }) => !tttaccounts.some(( {regNo: id2 }) => id2 === id1));

    //console.log("missing values : ", results);
    setMissingResults(results);

  }

  const fetchApplicationDetails=async(event, searchValue)=>{
    event.preventDefault();
    fetchCurrentYearDetails(searchValue);
  }

  const emailToggle = () => {
    //   console.log("clicked");
    setUpdateEmailCollapsed(!updateEmailCollapsed);
  }

  const handleEmailUpdateView =(event, item)=>{
    event.preventDefault();
    //console.log("item : ", item['Attributes'][1]['Value']);
   // setProfileDetails(item);
    setSelectedEmail(item['Attributes'][0]['Value']);
    setSelectedRegistrationNo(item['Attributes'][1]['Value']);
    setUpdateEmailCollapsed(!updateEmailCollapsed);
  }

  const handleCreateAccount=async(event, item)=>{
      //console.log("Item : ", item);
    event.preventDefault();
    try{
        const areaCode="+91";
      const tphone=areaCode.toString()+item.phoneNo.toString();
      if(item.dobOfficial===""||item.dobOfficial==="NA"){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Date of Birth needs to be Updated first. Go to Members section and Update the Date of Birth"
      });        
      }else if(!item.dobOfficial.match(dobRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Date of Birth needs to be Updated first. Go to Members section and Update the Date of Birth"
      });        
      }else if(item.emailId===""||item.emailId==="NA"){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Email Address needs to be Updated first. Go to Members section and Update the Email Address"
      });        
      }else if(!item.emailId.match(validRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Email address is not in right format!"
      }); 
      }else if(!tphone.match(phoneRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Area Code and Phone Number is not in right format!"
      }); 
      }else if(item.gender===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Gender Status needs to be Updated first. Go to Members section and Update the Gender"
      });        
      }else if(item.phoneNo==="NA"||item.phoneNo===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Area Code and Mobile Number needs to be Updated first. Go to Members section and Update the Area Code and Mobile Number"
      });        
      }else{
          setLoaded(false);
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken; 
          const {userId, username, emailId, dobOfficial, dateJoined, missionName, communityName } = item;
          const params={
            userId:userId,
            emailId:emailId,
            username:username,
            dateOfBirth:dobOfficial,
            dateJoined:dateJoined,
            phoneNo:tphone,
            missionName:missionName,
            communityName:communityName
          }
         // console.log("Params : ", params);
        //await axios.get(`${config.api.invokeURL}/dashboard/reports/fee-batchwise?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
          await axios.post(`${config.api.invokeUrl}/accounts/createnew`,params, {  
          headers: {
            Authorization: access_token,
            'x-api-key':config.api.key
          }
        }).then(async(response) => {
         //console.log("Response : ", response);
         if(response.status===215){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: response.data
            });  
         }else if(response.status===202){
            await fetchCurrentYearDetails();
              setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: "Login Account Created Successfully!"
            });  
        }
          setLoaded(true)
      }).catch((error)=>{
        console.log("Error : ", error);
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      });        
      setLoaded(true);
      window.scrollTo(0,0);
    })
  }
    }catch(error){
      console.log("Error2 : ", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    window.scrollTo(0,0);
    }
    window.scrollTo(0,0);
  }

return (
    <>
    <Helmet>
      <title>Login Account - Online Catalogue - SJ Madurai</title>
      <meta name="description" content="Login Account - Online Catalogue - SJ Madurai" />
      <meta name="keywords" content="Login Account - Online Catalogue - SJ Madurai" /> 
    </Helmet>

      <Preloader show={loaded ? false : true} />
      <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
      </Alert>
     {/*Update Email*/}
     <div>
        <Modal toggle={emailToggle} isOpen={!updateEmailCollapsed} style={{top:'10%'}}>
                    <ModalHeader toggle={emailToggle}>
                    Update - Email Address
                    </ModalHeader>
                    <ModalBody>    
                    <div className="text-center">
                      <EmailUpdateModal emailToggle={emailToggle} fetchCurrentYearDetails={fetchCurrentYearDetails} selectedEmail={selectedEmail} selectedRegistrationNo={selectedRegistrationNo}/>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                    <Button onClick={emailToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>
        </div>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item> Admin</Breadcrumb.Item>
            <Breadcrumb.Item active> Account</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Account Management</h4>
        </div>
        {/*<div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            
            <Button variant="outline-primary" size="sm">Export</Button>
          </ButtonGroup>
      </div>*/}
      </div>
 <Form.Group id="searchstud">
        <Form.Label>Select the Joining Year</Form.Label>  
     
      <div className="table-settings d-flex justify-content-between">
       
        
             
         <Form.Select value={selectedBatch} 
              onChange={(event)=>{setSelectedBatch(event.target.value)}}>
             {batchYear.map(item => {
                return (
                <option key={item} value={item}>{item}</option>
                );
              })}
          </Form.Select>
    
       <Button style={{width:"140px"}} variant="primary" type="submit" onClick={(event)=>{fetchApplicationDetails(event, selectedBatch)}}><i className='fa fa-search'> Search </i></Button>
   
         {/*} <Col className="mb-3" xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" value={q} onChange={(e) => setQ(e.target.value)}/>
              <Button variant="primary" type="submit" >Submit</Button>
            </InputGroup>
                            </Col>*/}
       
        </div>
 </Form.Group>
        {missingResults.length>0?<><h3>{missingResults.length} - Records found without Login accounts</h3>
        <Table ref={componentRef} hover responsive>
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Current Status</th>
                        <th>Email</th>  
                        <th>Mobile</th>     
                        <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {missingResults.map((item, index)=>

                    <tr key={index}>
                    <th scope="row">{index+1}</th>
                    <td>{item.username}</td>
                    <td>{item.currentStatus}</td>
                    <td> {item.emailId}</td>
                    <td>{item.phoneNo}</td>
                    <td> 
                    <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                    <span className="icon icon-sm">
                        <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                    </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    <Dropdown.Item onClick={(event)=>{handleCreateAccount(event,item)}} >
                        <FontAwesomeIcon icon={faPlusCircle} className="me-2" /> Create Account
                    </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                    </td>
                    </tr>)}
                    </tbody>
        </Table></>:null}

      <Table ref={componentRef} hover responsive>
             <thead>
                <tr>
                <th>#</th>
                <th> Name</th>
                <th>Email</th>
                <th>Account Status</th>  
                <th>Login Status</th>  
                <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {searchResults.length>0 && searchResults.map((item, index)=>

            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.Attributes[0]['Value']}</td>
            <td>{item.Attributes[1]['Value']}</td>
            <td>{item.Enabled?"Enabled":"Disabled"}</td>
            <td>{item.UserStatus}</td>
            <td> 
            <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(event)=>{handleEmailUpdateView(event,item)}} >
                <FontAwesomeIcon icon={faExchange} className="me-2" /> Change Email Address
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
            </Table>
    </>
  );
};
